<template>
    <div>
        <Header />
        <main>
            <div id="breadcrumb">
                <div class="container" v-if="user">
                    <ul>
                        <li><a href="#">Home</a></li>
                        <li v-if="user.role === 'doctor'"><a href="/doctors/?searchText=">I nostri medici</a></li>
                        <li v-else><a href="/profile">Profilo</a></li>
                        <li>{{ user.name }}</li>
                    </ul>
                </div>
            </div>
            <!-- /breadcrumb -->

            <div class="container margin_60" v-if="user">
                <div class="row">
                    <aside class="col-xl-3 col-lg-4" id="sidebar" v-if="doctor && user.role === 'doctor'">
                        <div class="box_profile">
                            <figure>
                                <img :src="doctor.profile_image_link === null || doctor.profile_image_link === '' ? 'https://www.placeholder.com/300x400' : doctor.profile_image_link"
                                    alt="" class="img-fluid" style="width: 306px">
                            </figure>
                            <small>{{ doctor.tag.tag }}</small>
                            <h1>{{ doctor.title }} {{ doctor.user.name }}</h1>
                            <span class="rating" v-if="average">
                                <i v-for="star in 5" :key="star" class="icon_star"
                                    :class="{ voted: star <= average }"></i>
                                <a href="badges.html" data-bs-toggle="tooltip" data-bs-placement="top"
                                    title="Badge Level" class="badge_list_1">
                                    <img src="/img/badges/badge_1.svg" width="15" height="15" alt="">
                                </a>
                            </span>

                            <div class="text-center"><a
                                    :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(doctor.address + ' ' + doctor.number + ' - ' + doctor.cap + ' ' + doctor.city + ' (' + doctor.code + ')')}`"
                                    target="_blank" class="btn_1 outline"><i class="icon_pin"></i> Vedi
                                    mappa</a></div>
                        </div>
                    </aside>
                    <!-- /asdide -->

                    <div
                        :class="{ 'col-xl-9 col-lg-8': user.role === 'doctor', 'col-xl-12 col-lg-12': user.role !== 'doctor' }">



                        <div class="tabs_styled_2">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a v-if="user.role === 'doctor'" class="nav-link active" id="general-tab"
                                        data-bs-toggle="tab" href="#general" role="tab" aria-controls="general"
                                        aria-expanded="true">Info Generali,
                                        Indirizzi, CV, Trattamenti</a>
                                    <a v-else class="nav-link active" id="general-tab" data-bs-toggle="tab"
                                        href="#general" role="tab" aria-controls="general"
                                        aria-expanded="true">Profilo</a>
                                </li>
                                <li class="nav-item" v-if="user.role === 'doctor'">
                                    <a class="nav-link" id="agenda-tab" data-bs-toggle="tab" href="#agenda" role="tab"
                                        aria-controls="agenda">Agenda appuntamenti</a>
                                </li>
                                <li class="nav-item" v-if="user.role === 'user'">
                                    <a class="nav-link" id="apps-tab" data-bs-toggle="tab" href="#apps" role="tab"
                                        aria-controls="apps">Appuntamenti</a>
                                </li>

                                <li class="nav-item" v-if="user.role === 'doctor'">
                                    <a class="nav-link" id="reviews-tab" data-bs-toggle="tab" href="#reviews" role="tab"
                                        aria-controls="reviews">Recensioni</a>
                                </li>

                            </ul>
                            <!--/nav-tabs -->

                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="general" role="tabpanel"
                                    aria-labelledby="general-tab">
                                    <div class="container-fluid">
                                        <!-- Breadcrumbs-->

                                        <div class="box_general padding_bottom">
                                            <div class="header_box version_2">
                                                <h2 v-if="user.role === 'doctor'"><i class="fa fa-user-md"></i>
                                                    Informazioni pubbliche</h2>
                                                <h2 v-else><i class="fa fa-user"></i> Informazioni pubbliche</h2>
                                                <p v-if="user.role === 'doctor'">Inserisci qui il tuo nome, cognome,
                                                    email, telefono e immagine del
                                                    profilo che saranno visibili sul tuo profilo</p>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label>Nome e cognome</label>
                                                        <input type="text" class="form-control"
                                                            placeholder="Il tuo nome e cognome" v-model="user.name"
                                                            autofocus>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /row-->
                                            <div class="row">
                                                <div class="col-md-6" v-if="doctor && user.role === 'doctor'">
                                                    <div class="form-group">
                                                        <label>Telefono</label>
                                                        <input type="text" class="form-control"
                                                            placeholder="Il tuo numero di telefono"
                                                            v-model="doctor.phone">
                                                    </div>
                                                </div>
                                                <div
                                                    :class="{ 'col-md-6': user.role === 'doctor', 'col-md-12': user.role !== 'doctor' }">
                                                    <div class="form-group">
                                                        <label>Email</label>
                                                        <input type="email" class="form-control"
                                                            placeholder="La tua email" v-model="user.email">
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /row-->


                                            <div style="margin-bottom: 15px" v-if="doctor && user.role === 'doctor'">
                                                <div v-bind="getRootProps()" @drop.prevent @dragover.prevent
                                                    style="cursor: pointer; border: 3px darkgray; border-style: dotted">
                                                    <input v-bind="getInputProps()" @change="saveUser" />
                                                    <p
                                                        style="text-align: center; margin-top: 30px; margin-bottom: 30px;">
                                                        Trascina o clicca qua l'immagine del profilo...</p>
                                                </div>
                                            </div>


                                            <!-- /row-->


                                            <div style="display: inline-flex;">
                                                <div style="margin-right: 10px">
                                                    <a href="#0" class="btn_1 medium" @click="saveUser">Salva /
                                                        Aggiorna</a>

                                                </div>
                                                <div>

                                                    <input type="button" @click="toggleChangePasswordModal"
                                                        class="btn_1 medium" value="Cambia Password" />

                                                </div>
                                            </div>

                                        </div>
                                        <!-- /box_general-->


                                        <div class="box_general padding_bottom" v-if="user.role === 'doctor' && doctor">
                                            <div class="header_box version_2">
                                                <h2><i class="fa fa-file-text"></i> Specializzazione e CV</h2>
                                            </div>

                                            <form @submit.prevent="handleChangeSpecializzazioneSubmit">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Titolo</label>
                                                            <select class="form-control" v-model="doctor.title">
                                                                <option value="Dott.">Dr.</option>
                                                                <option value="Dott.ssa">Dr.ssa</option>
                                                                <option value="Prof.">Prof.</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /row-->
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Specializzazione</label>
                                                            <select class="form-control" v-model="doctor.tag.id">
                                                                <option v-for="doctorTag in doctorTags"
                                                                    :key="doctorTag.id" :value="doctorTag.id">{{
                    doctorTag.tag }}</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- /row-->
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Curriculum Vitae</label><br>
                                                            <textarea id="summernote"
                                                                v-model="doctor.curriculum"></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p><button type="submit" class="btn_1 medium">Salva / Aggiorna</button>
                                                </p>
                                            </form>

                                            <!-- /row-->
                                        </div>
                                        <!-- /box_general-->



                                        <!-- GLI STUDI -->

                                        <div class="box_general padding_bottom" v-if="user.role === 'doctor' && doctor">
                                            <div class="header_box version_2">
                                                <h2><i class="fa fa-map-marker"></i> Indirizzo Studio Primario</h2>
                                            </div>
                                            <form @submit.prevent="handleChangeStudioSubmit">
                                                <div class="row studio-1">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>Nome dello studio primario</label>
                                                            <input type="text" class="form-control"
                                                                placeholder="Come si chiama il tuo studio primario?"
                                                                v-model="doctor.nome_studio">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control"
                                                                placeholder="Il tuo indirizzo" v-model="doctor.address">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control"
                                                                placeholder="Numero civico" v-model="doctor.number">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control"
                                                                placeholder="Provincia" v-model="doctor.city">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <select v-model="doctor.code" class="form-select">
                                                                <option v-for="(name, code) in provinces" :key="code"
                                                                    :value="code">{{ name }} ({{ code }})</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <input type="text" class="form-control" placeholder="CAP"
                                                                v-model="doctor.cap">
                                                        </div>
                                                    </div>

                                                </div>
                                                <button type="button" class="btn_1 gray add-pricing-list-item"
                                                    @click="toggleChangeAddSecondaryStudioModal"><i
                                                        class="fa fa-fw fa-plus-circle"></i>Aggiungi studio
                                                    secondario</button>
                                                <div class="row studio-2" style="margin-top: 15px;"
                                                    v-if="studios.length > 0">
                                                    <h2>Studi secondari</h2>
                                                    <table style="padding-right: 30px">
                                                        <tr v-for="studio in studios">
                                                            <td style="width: 95%">
                                                                <button type="button" class="btn btn-link"
                                                                    @click="getSecondaryStudio(studio.id)"> {{
                    studio.nome_studio }}</button>
                                                            </td>
                                                            <td style="width: 5%">
                                                                <a class="btn btn-danger btn-sm"
                                                                    @click="deleteSecondaryStudio(studio.id)"><i
                                                                        class="fa fa-trash"></i></a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                                <hr>
                                                <p><button type="submit" class="btn_1 medium">Salva / Aggiorna</button>
                                                </p>
                                            </form>
                                            <!-- /row-->
                                        </div>
                                        <!-- /box_general-->



                                        <div class="box_general padding_bottom" v-if="user.role === 'doctor'">
                                            <div class="header_box version_2">
                                                <h2><i class="fa fa-hand-paper-o"></i> Trattamenti e/o Interventi</h2>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h6>Aggiungi i trattamenti che vuoi che siano visibili e disponibili
                                                        per la presa appuntamento. Inserisci il tuo prezzo per ogni
                                                        trattamento</h6>
                                                    <button type="button" @click="toggleChangeAddServiceModal"
                                                        class="btn_1 gray add-pricing-list-item"><i
                                                            class="fa fa-fw fa-plus-circle"></i>Aggiungi trattamento /
                                                        intervento</button>
                                                    <div class="row studio-2" style="margin-top: 15px;"
                                                        v-if="services.length > 0">
                                                        <table style="padding-right: 30px">
                                                            <tr v-for="service in services">
                                                                <td style="width: 95%">
                                                                    <button type="button" class="btn btn-link"
                                                                        @click="getService(service.id)"> {{ service.name
                                                                        }}</button>
                                                                </td>
                                                                <td style="width: 5%">
                                                                    <a v-if="service.deletable" class="btn btn-danger btn-sm"
                                                                        @click="deleteService(service.id)"><i
                                                                            class="fa fa-trash"></i></a>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- /row-->
                                            <hr>

                                            <p><a href="#0" class="btn_1 medium">Salva / Aggiorna</a></p>

                                        </div>
                                        <!-- /box_general-->
                                    </div>


                                </div>
                                <!-- /tab_2 -->

                                <div v-if="doctor && user.role === 'doctor'" class="tab-pane fade" id="agenda"
                                    role="tabpanel" aria-labelledby="agenda-tab">
                                    <div class="reviews-container">
                                        <ProfileTabAgenda :user="user" :doctor="doctor" />
                                    </div>

                                </div>
                                <!-- /tab_3 -->

                                <div v-if="user && user.role === 'user'" class="tab-pane fade" id="apps" role="tabpanel"
                                    aria-labelledby="apps-tab">
                                    <div class="reviews-container">
                                        <ProfileTabApp :user="user" />
                                    </div>
                                </div>

                                <!-- tab 4-->

                                <div class="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                                    <div class="reviews-container">
                                        <!-- Genera dinamicamente i commenti qui -->
                                        <p v-if="comments.length === 0" class="empty-comments">La lista dei commenti è
                                            vuota</p>
                                        <div class="review-box clearfix" style="padding-left: unset;"
                                            v-for="comment in comments" :key="comment.id">
                                            <figure class="rev-thumb" style="display: none;"><img
                                                    :src="comment.avatar || 'http://via.placeholder.com/150x150.jpg'"
                                                    alt="User image">
                                            </figure>
                                            <div class="rev-content">
                                                <div class="rating">
                                                    <i class="icon_star" v-for="star in 5"
                                                        :class="{ voted: star <= comment.stars }"></i>
                                                </div>
                                                <div class="rev-info">
                                                    {{ comment.author_name }} – {{
                    formatDateFromString(comment.created_at) }}
                                                </div>
                                                <div class="rev-text">
                                                    <p>{{ comment.comment }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End review-container -->
                                    <hr>
                                    <div class="text-end" v-if="this.user && this.user.role == 'user'"><a
                                            class="btn_1 add_bottom_15" @click="modalToggle()">Scrivi una recensione</a>
                                    </div>
                                </div>

                            </div>



                            <!-- /tab-content -->
                        </div>
                        <!-- /tabs_styled -->
                    </div>
                    <!-- /col -->
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->



        </main>
        <!-- /main -->
        <Footer />
    </div>

    <!-- MODALS -->

    <!-- cambia password modal / form-->
    <div v-if="isModalChangePasswordOpen" class="my-modal">
        <div class="my-modal-content">
            <h2>Cambia password</h2>
            <form @submit.prevent="handleChangePasswordSubmit">
                <div class="form-group">
                    <label>Password:</label>
                    <input type="password" class="form-control" required v-model="passwordField1"
                        ref="passwordField1" />
                </div>
                <div class="form-group">
                    <label>Conferma password:</label>
                    <input type="password" class="form-control" required v-model="passwordField2" />
                </div>
                <div class="form-group buttons">
                    <input type="button" @click="toggleChangePasswordModal" class="btn btn-secondary btn-sm"
                        style="margin-right: 5px" value="Annulla" />
                    <input type="submit" class="btn btn-primary btn-sm" value="Salva" />
                </div>
            </form>
        </div>
    </div>
    <!-- ********-->

    <!-- servizi modal / form-->
    <div v-if="isModalAddService" class="my-modal">
        <div class="my-modal-content">
            <h2 v-if="this.service_id > 0">Modifica trattamento</h2>
            <h2 v-else>Aggiungi trattamento</h2>
            <form @submit.prevent="handleChangeServiceSubmit">
                <div class="row studio-1">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Nome del servizio</label>
                            <input type="text" class="form-control" v-model="service_name">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Prezzo del servizio</label>
                            <input type="number" class="form-control" v-model="service_price" step="1.00" min="0">
                        </div>
                    </div>
                    <div class="form-group buttons">
                        <input type="button" class="btn btn-secondary btn-sm" style="margin-right: 5px" value="Annulla"
                            @click="toggleChangeAddServiceModal" />
                        <input type="button" class="btn btn-primary btn-sm" value="Salva"
                            @click="handleChangeServiceSubmit" />
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- ********-->

    <!-- studi secondari modal / form-->
    <div v-if="isModalAddSecondaryStudio" class="my-modal">
        <div class="my-modal-content">
            <h2 v-if="ss_id > 0">Modifica studio secondario</h2>
            <h2 v-else>Aggiungi studio secondario</h2>
            <form @submit.prevent="handleChangeStudioSecondarioSubmit">
                <div class="row studio-1">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Nome dello studio secondario</label>
                            <input type="text" class="form-control" placeholder="Come si chiama il tuo studio secondario?"
                                v-model="ss_nome_studio">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Il tuo indirizzo" v-model="ss_address">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Numero civico" v-model="ss_number">
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Città" v-model="ss_city">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <select v-model="ss_code" class="form-select">
                                <option value="" selected disabled>Seleziona una provincia</option>
                                <option v-for="(name, code) in provinces" :key="code" :value="code">{{ name
                                    }} ({{ code }})</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="CAP" v-model="ss_cap">
                        </div>
                    </div>
                    <div class="form-group buttons">
                        <input type="button" @click="toggleChangeAddSecondaryStudioModal"
                            class="btn btn-secondary btn-sm" style="margin-right: 5px" value="Annulla" />
                        <input type="submit" class="btn btn-primary btn-sm" value="Salva" />
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- ********-->

</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import apiConfig from '@/config/api';
import { request, requestWihtoutPreload, responseKO } from '@/config/api'
import store from "@/store"
import { useDropzone } from "vue3-dropzone";
import ProfileTabAgenda from '@/components/ProfileComponents/ProfileTabAgenda.vue';
import ProfileTabApp from '@/components/ProfileComponents/ProfileTabApp.vue';

export default {
    name: 'ProfileView2',
    components: {
        Header, ProfileTabAgenda, ProfileTabApp, Footer
    },

    setup() {
        const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
            onDrop: (acceptedFiles) => {
                acceptedFiles.forEach(file => {
                    uploadFile(file);
                });
            }
        });

        const uploadFile = async (file) => {
            const directory = "profili";
            var loggedUser = null;
            var loggedDoctor = null;
            var linkProfileImage = null;
            //prima ricavo l'utente loggato
            const resp = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (resp.ok) {
                loggedUser = await resp.json();
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
                return;
            }
            const resp2 = await request(
                apiConfig.noAuthEndpoints.doctorByUserId + "/" + loggedUser.id,
                apiConfig.methods.get,
                null,
                store.getToken()
            );
            if (resp2.ok) {
                const d = await resp2.json();
                loggedDoctor = d.result.doctor;
                linkProfileImage = loggedDoctor.profile_image_link;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
                return;
            }

            // Ridimensiona l'immagine
            const img = new Image();
            const reader = new FileReader();

            reader.onload = (e) => {
                img.src = e.target.result;
            };

            reader.onloadend = async () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                img.onload = async () => {
                    const ratio = img.height / img.width;
                    canvas.width = 300;
                    canvas.height = 300 * ratio;
                    ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

                    canvas.toBlob(async (blob) => {
                        const uniqueName = uuidv4() + '_' + file.name;  // Genera un nome unico per il file
                        const resizedFile = new File([blob], uniqueName, { type: file.type });  // Crea un nuovo file con il nuovo nome

                        const formData = new FormData();
                        formData.append('file', resizedFile);
                        formData.append('folder', directory);

                        try {
                            const response = await fetch(apiConfig.endpoints.filemanagerUploadFile, {
                                method: 'POST',
                                headers: {
                                    'Authorization': 'Bearer ' + store.getToken()
                                },
                                body: formData
                            });
                            if (response.ok) {
                                //prima di modificare il record del dottore inserendo il path dell'immagine caricata, rimuovo l'immagine vecchia da file system
                                if (linkProfileImage) {
                                    const startIndex = linkProfileImage.lastIndexOf("profili/") + "profili/".length;
                                    const imageFileName = linkProfileImage.substring(startIndex);
                                    const body12 = {
                                        folder: directory,
                                        filename: imageFileName
                                    };
                                    const response12 = await request(apiConfig.endpoints.filemanagerDeleteFile, apiConfig.methods.delete, body12, store.getToken());
                                    if (response12.ok) {
                                        await response12.json();
                                    }
                                }
                                ////// Scrivo il path dell'immagine nel campo del record del dottore profile_image_link
                                const body = {
                                    profileImageLink: apiConfig.fileManagerStorageUrl + directory + "/" + uniqueName
                                };
                                const response2 = await request(
                                    apiConfig.endpoints.doctorUpdate + "/" + loggedDoctor.id,
                                    apiConfig.methods.put,
                                    body,
                                    store.getToken()
                                );
                                if (response2.ok) {
                                    const data = await response2.json();
                                    if (data.message === "OK") {
                                        document.location.reload();
                                    } else {
                                        showNotification(apiConfig.errors.generic, "error", 3000);
                                    }
                                }
                            } else {
                                showNotification("Errore durante la richiesta.", "error", 3000);
                            }
                        } catch (error) {
                            showNotification("Errore durante la richiesta.", "error", 3000);
                        }
                    }, file.type, 1);
                };
            };

            reader.readAsDataURL(file);
        };


        return {
            getRootProps,
            getInputProps,
            isDragActive,
            openFileDialog: open
        };
    },

    data() {
        return {
            user: null,
            doctor: null,
            passwordField1: "",
            passwordField2: "",
            average: 0,
            doctorTags: [],
            isModalChangePasswordOpen: false,
            isModalAddSecondaryStudio: false,
            isModalAddService: false,
            ss_id: 0,
            ss_nome_studio: "",
            ss_address: "",
            ss_number: "",
            ss_city: "",
            ss_code: "",
            ss_cap: "",
            service_id: 0,
            service_name: '',
            service_price: 0,
            services: [],
            studios: [],
            comments: [],
            provinces: {
                AG: 'Agrigento',
                AL: 'Alessandria',
                AN: 'Ancona',
                AO: 'Aosta',
                AR: 'Arezzo',
                AP: 'Ascoli Piceno',
                AT: 'Asti',
                AV: 'Avellino',
                BA: 'Bari',
                BT: 'Barletta-Andria-Trani',
                BL: 'Belluno',
                BN: 'Benevento',
                BG: 'Bergamo',
                BI: 'Biella',
                BO: 'Bologna',
                BZ: 'Bolzano',
                BS: 'Brescia',
                BR: 'Brindisi',
                CA: 'Cagliari',
                CL: 'Caltanissetta',
                CB: 'Campobasso',
                CI: 'Carbonia-Iglesias',
                CE: 'Caserta',
                CT: 'Catania',
                CZ: 'Catanzaro',
                CH: 'Chieti',
                CO: 'Como',
                CS: 'Cosenza',
                CR: 'Cremona',
                KR: 'Crotone',
                CN: 'Cuneo',
                EN: 'Enna',
                FM: 'Fermo',
                FE: 'Ferrara',
                FI: 'Firenze',
                FG: 'Foggia',
                FC: 'Forlì-Cesena',
                FR: 'Frosinone',
                GE: 'Genova',
                GO: 'Gorizia',
                GR: 'Grosseto',
                IM: 'Imperia',
                IS: 'Isernia',
                SP: 'La Spezia',
                AQ: 'L\'Aquila',
                LT: 'Latina',
                LE: 'Lecce',
                LC: 'Lecco',
                LI: 'Livorno',
                LO: 'Lodi',
                LU: 'Lucca',
                MC: 'Macerata',
                MN: 'Mantova',
                MS: 'Massa-Carrara',
                MT: 'Matera',
                VS: 'Medio Campidano',
                ME: 'Messina',
                MI: 'Milano',
                MO: 'Modena',
                MB: 'Monza e Brianza',
                NA: 'Napoli',
                NO: 'Novara',
                NU: 'Nuoro',
                OG: 'Ogliastra',
                OT: 'Olbia-Tempio',
                OR: 'Oristano',
                PD: 'Padova',
                PA: 'Palermo',
                PR: 'Parma',
                PV: 'Pavia',
                PG: 'Perugia',
                PU: 'Pesaro e Urbino',
                PE: 'Pescara',
                PC: 'Piacenza',
                PI: 'Pisa',
                PT: 'Pistoia',
                PN: 'Pordenone',
                PZ: 'Potenza',
                PO: 'Prato',
                RG: 'Ragusa',
                RA: 'Ravenna',
                RC: 'Reggio Calabria',
                RE: 'Reggio Emilia',
                RI: 'Rieti',
                RN: 'Rimini',
                RM: 'Roma',
                RO: 'Rovigo',
                SA: 'Salerno',
                SS: 'Sassari',
                SV: 'Savona',
                SI: 'Siena',
                SR: 'Siracusa',
                SO: 'Sondrio',
                TA: 'Taranto',
                TE: 'Teramo',
                TR: 'Terni',
                TO: 'Torino',
                TP: 'Trapani',
                TN: 'Trento',
                TV: 'Treviso',
                TS: 'Trieste',
                UD: 'Udine',
                VA: 'Varese',
                VE: 'Venezia',
                VB: 'Verbano-Cusio-Ossola',
                VC: 'Vercelli',
                VR: 'Verona',
                VV: 'Vibo Valentia',
                VI: 'Vicenza',
                VT: 'Viterbo',
            },
        }
    },
    created() {
        this.me();
    },
    updated() {
        $(document).ready(function () {
            $('#summernote').summernote({
                placeholder: 'Scrivi qui il contenuto dell\'articolo...',
                tabsize: 2,
                height: 300,
                toolbar: [
                    ['style', ['style']],
                    ['font', ['bold', 'italic', 'underline', 'clear']],
                    ['fontname', ['fontname']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['height', ['height']],
                    ['table', ['table']],
                    ['insert', ['link']],  // Rimuovi 'picture' e 'video' da qui
                    ['view', ['fullscreen', 'codeview', 'help']]
                ]
            });
        });
    },

    methods: {
        formatDateFromString(dateString) {
            // Parse the input string into a Date object
            const date = new Date(dateString);

            // Get the day, month, year, hour, and minute from the Date object
            const day = date.getDate();
            const month = date.getMonth() + 1; // getMonth() returns a 0-based value, so we add 1
            const year = date.getFullYear();
            const hour = date.getHours();
            const minute = date.getMinutes();

            // Format the month as a string with the Italian name
            const monthNames = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"];
            const monthName = monthNames[month - 1];

            // Format the minute with two digits
            const minuteString = minute.toString().padStart(2, "0");

            // Return the formatted string
            return `${day} ${monthName} ${year} (${hour}:${minuteString})`;
        },
        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        },
        setActiveTab(tabName) {
            this.activeTab = tabName;
        },
        async me() {
            const response = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.user = data;
                if (!this.isObjectEmpty(this.user)) {
                    if (this.user.role === 'doctor') {
                        await this.getDoctor();
                        await this.getAverageDoctor(this.user.id);
                        await this.getDoctorTags();
                        await this.getSecondaryStudios();
                        await this.getServices();
                        await this.getComments();
                    }
                } else {
                    responseKO();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async getDoctor() {
            const endpoint = apiConfig.noAuthEndpoints.doctorByUserId + "/" + this.user.id;
            const response = await requestWihtoutPreload(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.doctor = data.result.doctor;
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async saveUser() {
            if (this.user.name.length === 0 || this.user.email.length === 0) {
                showNotification("Attenzione. I campi nome ed email sono obbligatori.", "warning", 3000);
                return;
            }
            var body;
            if (this.user.role != 'doctor') {
                body = {
                    name: this.user.name,
                    email: this.user.email,
                    role: this.user.role
                };
            } else {
                body = {
                    name: this.user.name,
                    email: this.user.email,
                    role: this.user.role,
                    phone: this.doctor.phone
                };
            }
            const endpoint = apiConfig.endpoints.userUpdate + "/" + this.user.id;
            const response = await requestWihtoutPreload(endpoint, apiConfig.methods.put, body, store.getToken());
            if (response.ok) {
                showNotification("Profilo aggiornato con successo", "success", 3000);
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async handleChangePasswordSubmit() {
            const id = this.user.id;
            const password1 = this.passwordField1;
            const password2 = this.passwordField2;
            // Verifica che tutti i campi siano compilati
            if (!password1 || !password2) {
                showNotification('Compila tutti i campi prima di continuare.', "warning", 4000);
                return;
            }
            if (password1 !== password2) {
                showNotification('Le password indicate sono diverse, riprova.', "warning", 4000);
                return;
            }
            const requestBody = {
                password1,
                password2
            };
            const changePasswordEndpoint = apiConfig.endpoints.changePasswordUser + '/' + id;
            const response = await request(changePasswordEndpoint, apiConfig.methods.post, requestBody, store.getToken());
            if (response.ok) {
                showNotification("La password è stata cambiata correttamente.", "success", 4000);
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
            this.updateUI();
            this.toggleChangePasswordModal();
        },
        updateUI() {
            this.passwordField1 = "",
                this.passwordField2 = ""
        },
        toggleChangePasswordModal() {
            this.isModalChangePasswordOpen = !this.isModalChangePasswordOpen;
        },
        toggleChangeAddSecondaryStudioModal() {
            this.isModalAddSecondaryStudio = !this.isModalAddSecondaryStudio;
            if (!this.isModalAddSecondaryStudio) {
                this.resetStudio();
            }
        },
        toggleChangeAddServiceModal() {
            this.isModalAddService = !this.isModalAddService;
            if (!this.isModalAddService) {
                this.resetService();
            }
        },
        async getAverageDoctor(userId) {
            const endpoint = apiConfig.endpoints.commentsAverage + "/" + userId;
            const response = await request(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.average = data.result.average_stars;
                }
            }
            return 0;
        },
        async getDoctorTags() {
            const endpoint = apiConfig.endpoints.doctorTagsList;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.doctorTags = data.result.tags
                }
            }
            return 0;
        },
        async handleChangeSpecializzazioneSubmit() {
            const cv = $('#summernote').summernote().val();
            const requestBody = {
                title: this.doctor.title,
                doctors_tag_id: this.doctor.tag.id,
                curriculum: cv
            };
            const endpoint = apiConfig.endpoints.doctorUpdate + '/' + this.doctor.id;
            const response = await request(endpoint, apiConfig.methods.put, requestBody, store.getToken());
            if (response.ok) {
                showNotification("La sezione \"Specializzazione e Curriculum\" è stata aggiornata correttamente.", "success", 4000);
                setTimeout(function () {
                    window.location.href = "/profile";
                }, 4000);
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async handleChangeStudioSubmit() {
            const requestBody = {
                nome_studio: this.doctor.nome_studio,
                address: this.doctor.address,
                number: this.doctor.number,
                city: this.doctor.city,
                code: this.doctor.code,
                cap: this.doctor.cap
            };
            const endpoint = apiConfig.endpoints.doctorUpdate + '/' + this.doctor.id;
            const response = await request(endpoint, apiConfig.methods.put, requestBody, store.getToken());
            if (response.ok) {
                showNotification("La sezione degli studi è stata aggiornata correttamente.", "success", 4000);
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async handleChangeStudioSecondarioSubmit() {
            if (!this.ss_nome_studio || !this.ss_address || !this.ss_number || !this.ss_city || !this.ss_code || !this.ss_cap) {
                showNotification("Attenzione. Tutti i campi sono obbligatori.", "warning", 4000);
            } else {
                const requestBody = {
                    nome_studio: this.ss_nome_studio,
                    address: this.ss_address,
                    number: this.ss_number,
                    city: this.ss_city,
                    code: this.ss_code,
                    cap: this.ss_cap
                };
                var endpoint = "";
                var response = null;
                if (this.ss_id > 0) {
                    //modifico lo studio secondario
                    endpoint = apiConfig.endpoints.secondaryStudioUpdate + '/' + this.ss_id;
                    response = await request(endpoint, apiConfig.methods.put, requestBody, store.getToken());
                } else {
                    //aggiungo lo studio secondario
                    endpoint = apiConfig.endpoints.secondaryStudioAdd + '/' + this.doctor.id;
                    response = await request(endpoint, apiConfig.methods.post, requestBody, store.getToken());
                }
                if (response.ok) {
                    showNotification("Studio secondario salvato correttamente.", "success", 4000);
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000);
                }
                this.resetStudio();
                this.toggleChangeAddSecondaryStudioModal();
                this.getSecondaryStudios();
            }
        },
        async getSecondaryStudios() {
            const endpoint = apiConfig.endpoints.secondaryStudioList + "/" + this.doctor.id;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.studios = data.result.studios;
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async handleChangeServiceSubmit() {
            if (!this.service_name || !this.service_price) {
                showNotification("Attenzione. Tutti i campi sono obbligatori.", "warning", 3000);
                return;
            }
            const requestBody = {
                doctor_id: this.doctor.id,
                name: this.service_name,
                price: this.service_price
            };
            var endpoint = "";
            var method = "";
            if (this.service_id > 0) {
                endpoint = apiConfig.endpoints.serviceUpdate + "/" + this.service_id;
                method = apiConfig.methods.put;
            } else {
                endpoint = apiConfig.endpoints.serviceAdd;
                method = apiConfig.methods.post;
            }
            var response = await request(endpoint, method, requestBody, store.getToken());
            if (response.ok) {
                showNotification("Servizio salvato correttamente.", "success", 3000);
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
            this.resetService();
            this.getServices();
            this.toggleChangeAddServiceModal();
        },
        async getSecondaryStudio(id) {
            const endpoint = apiConfig.endpoints.secondaryStudioRead + "/" + id;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    const studio = data.result.studio;
                    this.ss_id = studio.id;
                    this.ss_nome_studio = studio.nome_studio;
                    this.ss_address = studio.address;
                    this.ss_number = studio.number;
                    this.ss_city = studio.city;
                    this.ss_code = studio.code;
                    this.ss_cap = studio.cap;
                    this.toggleChangeAddSecondaryStudioModal();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async deleteSecondaryStudio(id) {
            let text = "Sei sicuro di rimuovere questo studio secondario?";
            if (confirm(text) == true) {
                const endpoint = apiConfig.endpoints.secondaryStudioDelete + "/" + id;
                const response = await request(endpoint, apiConfig.methods.delete, null, store.getToken());
                if (response.ok) {
                    const data = await response.json();
                    if (data.message === "OK") {
                        showNotification("Studio rimosso correttamente.", "success", 3000);
                        this.getSecondaryStudios();
                    }
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000);
                }
            }
        },
        async getService(id) {
            const endpoint = apiConfig.endpoints.serviceRead + "/" + id;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                console.log(data)
                if (data.message === "OK") {
                    const service = data.result.service;
                    this.service_id = service.id;
                    this.service_name = service.name;
                    this.service_price = service.price;
                    this.toggleChangeAddServiceModal();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async deleteService(id) {
            let text = "Sei sicuro di rimuovere questo trattamento?";
            if (confirm(text) == true) {
                const endpoint = apiConfig.endpoints.serviceDelete + "/" + id;
                const response = await request(endpoint, apiConfig.methods.delete, null, store.getToken());
                if (response.ok) {
                    const data = await response.json();
                    if (data.message === "OK") {
                        showNotification("Trattamento rimosso correttamente.", "success", 3000);
                        this.getServices();
                    } else {
                        showNotification(data.result.error, "warning", 3000); //ci sono appuntamenti con questo servizio
                    }
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000);
                }
            }
        },
        async getServices() {
            const endpoint = apiConfig.endpoints.serviceList + "/" + this.doctor.id;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                this.services = data.result.services;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        resetStudio() {
            this.ss_id = 0;
            this.ss_nome_studio = "";
            this.ss_address = "";
            this.ss_number = "";
            this.ss_city = "";
            this.ss_code = "";
            this.ss_cap = "";
        },
        resetService() {
            this.service_id = 0;
            this.service_name = '';
            this.service_price = 0;
        },
        async getComments() {
            const endpoint = apiConfig.endpoints.commentsListForDoctor + "/" + this.doctor.user.id
            const response = await request(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.comments = data.result.comments;
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000)
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000)
            }
        },
    }
}
</script>

<style scoped>
.title {
    margin-top: 25px;
    text-align: center;
}

.profileTabsheet {
    padding: 15px 0 30px 0;
}

.my-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.my-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    position: relative;
}

.close {
    color: #aaaaaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
</style>