<template>
    <div>
        <AdminLTEMenu />
        <div class="content-wrapper" ref="contentWrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Gestione commenti</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/admin">Home</a></li>
                                <li class="breadcrumb-item active">Gestione commenti</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-body">
                                    <table id="commentTable" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Utente (autore)</th>
                                                <th>Dottore (destinatario)</th>
                                                <th>Commento</th>
                                                <th style="width: 150px;"></th>
                                            </tr>
                                            <tr>
                                                <th><input type="text" class="form-control w-100"
                                                        v-model="filters.author" placeholder="Cerca per autore..."></th>
                                                <th><input type="text" class="form-control w-100"
                                                        v-model="filters.recipient"
                                                        placeholder="Cerca per destinatario..."></th>
                                                <th><input type="text" class="form-control w-100"
                                                        v-model="filters.comment" placeholder="Cerca nel commento...">
                                                </th>
                                                <th><button class="btn btn-light" @click="clearFilters()">Pulisci
                                                        filtro</button></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="comment in paginatedComments" :key="comment.id">
                                                <td>{{ comment.author.name }}</td>
                                                <td>{{ comment.recipient.name }}</td>
                                                <td>{{ comment.comment }}</td>
                                                <td>
                                                    <a class="btn btn-light" @click="removeComment(comment.id)"><i
                                                            class="fas fa-trash"></i> Rimuovi</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <pagination :total-items="comments.length" :current-page.sync="currentPage"
                                        :page-size="pageSize" @page-changed="fetchPage"></pagination>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import apiConfig from '@/config/api';
import store from "@/store";
import { request, responseKO } from '@/config/api'
import AdminLTEMenu from '../../components/AdminComponents/AdminLTEMenu.vue';
import Pagination from '@/views/Pagination.vue';

export default {
    components: {
        AdminLTEMenu,
        Pagination
    },
    data() {
        return {
            user: null,
            comments: [],
            currentPage: 1,
            pageSize: 10,
            filters: {
                author: '',
                recipient: '',
                comment: ''
            },
        };
    },
    mounted() {
        this.fetchComments();
    },
    created() {
        this.me();
    },
    computed: {
        // paginatedComments() {
        //     const start = (this.currentPage - 1) * this.pageSize;
        //     const end = start + this.pageSize;
        //     return this.comments.slice(start, end);
        // }
        paginatedComments() {
            let filtered = this.comments.filter(comment => {
                return (!this.filters.author || comment.author.name.toLowerCase().includes(this.filters.author.toLowerCase())) &&
                    (!this.filters.recipient || comment.recipient.name.toLowerCase().includes(this.filters.recipient.toLowerCase())) &&
                    (!this.filters.comment || comment.comment.toLowerCase().includes(this.filters.comment.toLowerCase()));
            });
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return filtered.slice(start, end);
        }
    },
    methods: {
        clearFilters() {
            this.filters.author = '';
            this.filters.recipient = '';
            this.filters.comment = '';
        },
        fetchPage(page) {
            this.currentPage = page;
        },
        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        },
        async me() {
            const response = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.user = data;
                if (!this.isObjectEmpty(this.user)) {
                    if (this.user.role != 'admin') {
                        responseKO();
                    }
                } else {
                    responseKO();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },

        async fetchComments() {
            const response = await request(apiConfig.endpoints.comments, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                this.comments = data.result.comments;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },

        async removeComment(id) {
            if (confirm("Sei sicuro di rimuovere questo commento?") == true) {
                const response = await request(apiConfig.endpoints.commentsDelete + "/" + id, apiConfig.methods.delete, null, store.getToken());
                if (response.ok) {
                    const data = await response.json();
                    this.userCount = data.userCount;
                } else {
                    showNotification("Errore nel conteggio degli utenti.", "error", 3000);
                }
            }
        },

        updateSidebarHeight() {
            this.$nextTick(() => {
                const contentHeight1 = this.$refs.contentWrapper.clientHeight;
                const contentHeight2 = window.innerHeight;
                const maxHeight = Math.max(contentHeight1, contentHeight2);
                const sidebar = document.querySelector('.main-sidebar');
                if (sidebar) {
                    sidebar.style.minHeight = `${maxHeight}px`;
                }
            });
        },

    },

    updated() {
        this.updateSidebarHeight();
    }
}

</script>

<style scoped>
.action-column {
    text-align: center;
}

#commentTable {
    margin-bottom: 15px;
}
</style>