<template>
    <div>
        <AdminLTEMenu />

        <div class="content-wrapper" ref="contentWrapper">

            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Gestione articoli ({{ editionFormattedDate }})</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/admin">Home</a></li>
                                <li class="breadcrumb-item active"><a href="/admin/magazines">Gestione riviste</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">


                    <!-- Contenuto della pagina -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card">

                                <div class="card-header">
                                    <button class="btn btn-light" @click="handleArticle(0)"><i class="fas fa-plus"></i> Nuovo articolo</button>
                                </div>

                                <!-- /.card-header -->
                                <div class="card-body" v-if="articles.length">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Posizione</th>
                                                <th>Titolo</th>
                                                <th>Sottotitolo</th>
                                                <th>Autore</th>
                                                <th>Azioni</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(article, index) in articles" :key="article.id_article">
                                                <td>{{ article.position }}</td>
                                                <td>{{ article.title }}</td>
                                                <td>{{ article.subtitle }}</td>
                                                <td>{{ article.authorName }}</td>
                                                <td>
                                                    <button @click="handleArticle(article.id_article)" class="btn btn-primary btn-sm"><i class="fas fa-edit"></i> Modifica</button>
                                                    <button @click="deleteArticle(article.id_article)" class="btn btn-danger btn-sm" style="margin-left: 5px"><i class="fas fa-trash"></i> Elimina</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="card-body" v-else>
                                    Nessun articolo trovato per questa edizione.
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
            </section>

            <!-- Modal aggiunta edizione -->
            <div class="modal fade" id="modal-article-add">
                <div class="modal-dialog modal-custom-size">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Aggiungi articolo</h4>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        

                       


                    </div>
                </div>
            </div>

        </div>
    </div>
</template>


<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from '@/store';
import { ref } from 'vue';
import AdminLTEMenu from '../../components/AdminComponents/AdminLTEMenu.vue'

export default {
    components: {
        AdminLTEMenu,
        VueDatePicker
    },
    data() {
        return {
            articles: [],
            editionId: null,
            editionDate: null,
            editionFormattedDate: null
        }
    },
    methods: {
        handleArticle(id) {
            this.$router.push({
                name: 'adminArticle',
                params: {
                    articleId: id,
                    editionId: this.editionId,
                    date: this.editionFormattedDate
                }
            });
        },
        formattaData(dataString) {
            const mesi = ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];
            const data = new Date(dataString);
            const giorno = data.getDate();
            const mese = mesi[data.getMonth()];
            const anno = data.getFullYear();
            return `${mese} ${anno}`;
        },
        async searchEdition() {
            const endpoint = apiConfig.endpoints.editionRead + "/" + this.editionId;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.editionDate = data.result.edition.date;
                    this.editionFormattedDate = this.formattaData(this.editionDate);
                } else {
                    showNotification("Errore.", "error", 3000); 
                }
            } else {
                showNotification("Errore.", "error", 3000); 
            }
        },
        async fetchArticles() {
            const endpoint = apiConfig.endpoints.articleList + "/" + this.editionId;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            const data = await response.json();
            this.articles = data.result.articles || null;
        },
        async deleteArticle(id) {
            let text = "Sei sicuro di rimuovere l'articolo?";
            if (confirm(text) == true) {
                const response = await request(apiConfig.endpoints.articleDelete + "/" + id, apiConfig.methods.delete, null, store.getToken());
                if (response.ok) {
                    const data = await response.json();
                    if (data.message === "OK") {
                        if (data.result.isDeleted) {
                            showNotification("Articolo rimosso correttamente.", "success", 3000);
                            this.fetchArticles();
                        } else {
                            showNotification("Non è stato possibile rimuovere l'articolo.", "error", 3000); 
                        }
                    } else {
                        showNotification(data.result.error, "error", 3000); 
                    }
                } else {
                    showNotification("Errore.", "error", 3000); 
                }
            }
        },


        updateSidebarHeight() {
            this.$nextTick(() => {
                const contentHeight1 = this.$refs.contentWrapper.clientHeight;
                const contentHeight2 = window.innerHeight;
                const maxHeight = Math.max(contentHeight1, contentHeight2);
                const sidebar = document.querySelector('.main-sidebar');
                if (sidebar) {
                    sidebar.style.minHeight = `${maxHeight}px`;
                }
            });
        },

    },

    updated() {
        this.updateSidebarHeight();
    },
    mounted() {
        if (this.$route.query.showNotification === 'true') {
            showNotification("Articolo salvato correttamente.", "success", 3000);
        }
        this.editionId = this.$route.params.editionId || null
        this.searchEdition();
        this.fetchArticles();
    }

}
</script>

<style scoped>


</style>