<template>
  <div>
  <div class="layer"></div>


  <div class="bg_color_1">
    <div class="container margin_120_95">
      <div class="main_title">
        <h2>I top medici di Infoestetica</h2>
        <p>
          I nostri medici più richiesti dal web!
        </p>
      </div>
      <div id="reccomended" class="owl-carousel owl-theme">
        <div class="item">
          <a href="#">
            <div class="views hidden"><i class="icon-eye-7"></i>140</div>
            <div class="title">
              <h4>Prof. Andrea Garelli<em>Chirurgo Plastico</em></h4>
            </div>
            <img src="img/dottori/garelli.jpg" alt="" />
          </a>
        </div>
        <div class="item">
          <a href="#">
            <div class="views hidden"><i class="icon-eye-7"></i>140</div>
            <div class="title">
              <h4>Dr.ssa Paola Tarantino<em>Medico Estetico</em></h4>
            </div>
            <img src="img/dottori/tarantino.jpg" alt="" />
          </a>
        </div>
        <div class="item">
          <a href="#">
            <div class="views hidden"><i class="icon-eye-7"></i>140</div>
            <div class="title">
              <h4>Prof. Marco Toscani<em>Chirurgo Plastico</em></h4>
            </div>
            <img src="img/dottori/toscani.jpg" alt="" />
          </a>
        </div>
        <div class="item">
          <a href="#">
            <div class="views hidden"><i class="icon-eye-7"></i>140</div>
            <div class="title">
              <h4>Dr. Ciro De Sio<em>Chirurgo Plastico</em></h4>
            </div>
            <img src="img/dottori/de-sio.jpg" alt="" />
          </a>
        </div>
        <div class="item">
          <a href="#">
            <div class="views hidden"><i class="icon-eye-7"></i>140</div>
            <div class="title">
              <h4>Dr. Giulio Giammarioli<em>Medico Estetico</em></h4>
            </div>
            <img src="img/dottori/dr-giulio.jpg" alt="" />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  mounted() {
    // Carousel
	  $('#reccomended, #staff').owlCarousel({
      center: true,
      items: 2,
      loop: true,
      margin: 10,
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 2
        },
        1000: {
          items: 4
        }
      }
    });
  } 
};
</script>

<style>
.hidden {
  display: none;
}
</style>