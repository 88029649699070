<template>
  <div class="container margin_120_95">
    <div class="main_title">
      <h2>Trattamenti e Interventi</h2>
      <p>
        I trattamenti più richiesti!
      </p>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <a class="box_cat_home">
          <i class="icon-info-4" style="display: none"></i>
          <img
            src="img/icone/mastoplastica.png"
            width="60"
            height="60"
            alt=""
          />
          <h3>Mastoplastica</h3>
          <ul class="clearfix">
            <li><strong>+99</strong>Dottori</li>
            <li><strong>+99</strong>Cliniche</li>
          </ul>
        </a>
      </div>
      <div class="col-lg-3 col-md-6">
        <a class="box_cat_home">
          <i class="icon-info-4" style="display: none"></i>
          <img src="img/icone/liposuzione.png" width="60" height="60" alt="" />
          <h3>Liposuzione</h3>
          <ul class="clearfix">
            <li><strong>+99</strong>Dottori</li>
            <li><strong>+99</strong>Cliniche</li>
          </ul>
        </a>
      </div>
      <div class="col-lg-3 col-md-6">
        <a class="box_cat_home">
          <i class="icon-info-4" style="display: none"></i>
          <img src="img/icone/rinoplastica.png" width="60" height="60" alt="" />
          <h3>Rinoplastica</h3>
          <ul class="clearfix">
            <li><strong>+99</strong>Dottori</li>
            <li><strong>+99</strong>Cliniche</li>
          </ul>
        </a>
      </div>
      <div class="col-lg-3 col-md-6">
        <a class="box_cat_home">
          <i class="icon-info-4" style="display: none"></i>
          <img
            src="img/icone/gluteoplastica.png"
            width="60"
            height="60"
            alt=""
          />
          <h3>Gluteoplastica</h3>
          <ul class="clearfix">
            <li><strong>+99</strong>Dottori</li>
            <li><strong>+99</strong>Cliniche</li>
          </ul>
        </a>
      </div>
      <div class="col-lg-3 col-md-6">
        <a class="box_cat_home">
          <i class="icon-info-4" style="display: none"></i>
          <img
            src="img/icone/gluteoplastica.png"
            width="60"
            height="60"
            alt=""
          />
          <h3>Gluteoplastica</h3>
          <ul class="clearfix">
            <li><strong>+99</strong>Dottori</li>
            <li><strong>+99</strong>Cliniche</li>
          </ul>
        </a>
      </div>
      <div class="col-lg-3 col-md-6">
        <a class="box_cat_home">
          <i class="icon-info-4" style="display: none"></i>
          <img
            src="img/icone/gluteoplastica.png"
            width="60"
            height="60"
            alt=""
          />
          <h3>Gluteoplastica</h3>
          <ul class="clearfix">
            <li><strong>+99</strong>Dottori</li>
            <li><strong>+99</strong>Cliniche</li>
          </ul>
        </a>
      </div>
      <div class="col-lg-3 col-md-6">
        <a class="box_cat_home">
          <i class="icon-info-4" style="display: none"></i>
          <img
            src="img/icone/gluteoplastica.png"
            width="60"
            height="60"
            alt=""
          />
          <h3>Gluteoplastica</h3>
          <ul class="clearfix">
            <li><strong>+99</strong>Dottori</li>
            <li><strong>+99</strong>Cliniche</li>
          </ul>
        </a>
      </div>
      <div class="col-lg-3 col-md-6">
        <a class="box_cat_home">
          <i class="icon-info-4" style="display: none"></i>
          <img
            src="img/icone/gluteoplastica.png"
            width="60"
            height="60"
            alt=""
          />
          <h3>Gluteoplastica</h3>
          <ul class="clearfix">
            <li><strong>+99</strong>Dottori</li>
            <li><strong>+99</strong>Cliniche</li>
          </ul>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

</style>