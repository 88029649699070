import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from '@/store';

export async function getServices(doctorId) {
    var retval = [];
    const endpoint = apiConfig.endpoints.serviceList + "/" + doctorId;
    const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
    if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
            retval = data.result.services;
        } else {
            showNotification(data.message, "error", 3000);
        }
    } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
    }
    return retval;
}