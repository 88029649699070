<template>
    <div>
        <AdminLTEMenu />

        <div class="content-wrapper" ref="contentWrapper">

            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6" v-if="articleId == 0">
                            <h1 v-if="date">Nuovo articolo per l'edizione di {{ date }}</h1>
                        </div>
                        <div class="col-sm-6" v-if="articleId > 0">
                            <h1>Modifica articolo</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/admin">Home</a></li>
                                <li class="breadcrumb-item active"><a href="/admin/magazines">Gestione riviste</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">


                    <!-- Contenuto della pagina -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card">

                                <!-- /.card-header -->
                                <div class="card-body">

                                    <form @submit.prevent="submitArticle" enctype="multipart/form-data">
                                        <input type="hidden" v-model="articleId" />
                                        <input type="hidden" v-model="editionId" />
                                        <div class="form-group">
                                            <div class="custom-control custom-checkbox">
                                                <input class="custom-control-input" type="checkbox" value="" v-model="articleInterview" id="row1" @change="handleArticleDottorInterviewed()">
                                                <label for="row1" class="custom-control-label">L'articolo è
                                                    un'intervista?</label>
                                            </div>
                                        </div>
                                        <div class="form-group" v-if="articleInterview">
                                            <label for="row24">Dottore intervistato</label>
                                            <div class="row">
                                                <select class="form-control" id="row24" v-model="articleDottorInterviewed">
                                                    <option value="0" selected>Seleziona un dottore...</option>
                                                    <option v-for="dottore in dottoriDaIntervistare" :key="dottore.id" :value="dottore.id">
                                                        {{ dottore.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Autore:</label><br>
                                            <select class="form-control" v-model="editorId">
                                                <option v-for="editor in editors" :key="editor.id" :value="editor.id">
                                                    {{ editor.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-check-label" for="row0"
                                                style="padding-left: 10px; padding-top: 5px">Inserisci il link
                                                dell'immagine principale dell'articolo:</label><br>
                                            <input type="text" id="row0" v-model="articleImage" class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <label for="row2">Posizione dell'articolo nell'indice (edizione)</label>
                                            <input class="form-control" type="number" value="0"
                                                v-model="articlePosition" id="row2">
                                        </div>

                                        <div class="form-group">
                                            <label for="row23">Categorie associate</label>
                                            <div class="row">
                                                <div class="col-md-9">
                                                <select class="form-control select2 select2-hidden-accessible" multiple="" data-placeholder="Seleziona..." style="width: 100%;" tabindex="-1" aria-hidden="true">
                                                    <option v-for="category in categories" :key="category.id_tag" :value="category.id_tag">{{ category.tag_name }}</option>
                                                </select>
                                                </div>
                                                <div class="col-md-3">
                                                <a href="/admin/tags" target="_blank" class="btn btn-light btn-block">Gestisci categorie</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="row3">Titolo</label>
                                            <input type="text" class="form-control" id="row3" v-model="articleTitle"
                                                placeholder="Inserisci il titolo...">
                                        </div>
                                        <div class="form-group">
                                            <label for="row4">Sottotitolo</label>
                                            <input type="text" class="form-control" id="row4" v-model="articleSubtitle"
                                                placeholder="Inserisci il sottotitolo...">
                                        </div>
                                        <div class="form-group">
                                            <div class="card-body" style="margin-left: -20px">
                                                <label for="summernote">Testo</label>
                                                <textarea id="summernote" v-model="articleBody"></textarea>
                                            </div>
                                            <div class="card-footer">
                                                Documentazione di <a
                                                    href="https://github.com/summernote/summernote/">Summernote</a>
                                            </div>
                                            <br>
                                            <div>
                                                <button type="submit" class="btn btn-primary">Salva</button>
                                            </div>
                                        </div>
                                    </form>






                                </div>

                            </div>

                        </div>

                    </div>


                </div>
            </section>


        </div>
    </div>
</template>


<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from '@/store';
import AdminLTEMenu from '../../components/AdminComponents/AdminLTEMenu.vue'

export default {
    components: {
        AdminLTEMenu,
        VueDatePicker
    },
    data() {
        return {
            categories: [],
            selectedCategories: [],
            editors: [],
            editorId: null,
            articleId: '',
            articleTitle: '',
            articleSubtitle: '',
            articleBody: '',
            articleInterview: '',
            articlePosition: '',
            articleImage: '',
            articleDottorInterviewed: '0',
            dottoriDaIntervistare: '0',
            dottore: null
        }
    },
    methods: {
        handleArticleDottorInterviewed() {
            if(!this.articleInterview) {
                this.articleDottorInterviewed = '0';
            }
            if(this.articleInterview && !this.articleDottorInterviewed) {
                this.articleDottorInterviewed = '0';
            }
        },
        resetFields() {
            this.editorId = null;
            this.articleId = '';
            this.articleTitle = '';
            this.articleSubtitle = '';
            this.articleBody = '';
            this.articleInterview = false;
            this.articleDottorInterviewed = '0';
            this.articlePosition = '1';
            this.articleImage = '';
            this.selectedCategories = [];
        },
        async fetchArticle() {
            const response = await request(apiConfig.endpoints.articleRead + "/" + this.articleId, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                const article = data.result.article;
                this.articleImage = article.image == "null" ? '' : article.image;
                this.articleTitle = article.title;
                this.articleSubtitle = article.subtitle;
                this.articleBody = article.body;
                this.articlePosition = article.position;
                this.articleInterview = article.is_interview ? true : false;
                this.articleDottorInterviewed = article.doctor_interviewed;
                this.editorId = article.id_user;
                this.$nextTick(() => {
                    $('#summernote').summernote('code', this.articleBody);
                });
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async fetchEditors() {
            const response = await request(apiConfig.noAuthEndpoints.editorList, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                this.editors = data.result.editors;
                if (this.editors.length == 0) {
                    showNotification("Attenzione. Non ci sono editori censiti, pertanto non potrai salvare l'articolo.", "warning", 6000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async submitArticle() {

            if (!this.editorId || !this.articleTitle.trim() || !this.articleSubtitle.trim()) {
                showNotification("Attenzione. I campi 'Autore', 'Titolo', 'Sottotitolo' e 'Testo' sono obbligatori", "warning", 5000);
                return;
            }

            if(this.articleInterview == 1) {
                if(this.articleDottorInterviewed === '0') {
                    showNotification("Attenzione. Il campo dottore intervistato è obbligatorio se l'articolo è un'intervista.", "warning", 5000);
                    return;
                }
            } 

            const body = $('#summernote').summernote().val();
            if(body) {
                this.articleBody = body;
            } else {
                this.articleBody = '';
            }
            
            var formData = new FormData();
            formData.append('id_article', this.articleId);
            formData.append('id_edition', this.editionId);
            formData.append('id_user', this.editorId); //l'autore dell'articolo
            formData.append('is_interview', this.articleInterview ? 1 : 0);
            formData.append('title', this.articleTitle);
            formData.append('subtitle', this.articleSubtitle);
            formData.append('body', this.articleBody);
            formData.append('position', this.articlePosition);
            formData.append('image', this.articleImage);
            formData.append('tags', this.selectedCategories);
            
            if(this.articleDottorInterviewed && this.articleDottorInterviewed !== '0') {
                formData.append('doctor_interviewed', this.articleDottorInterviewed);
            } else {
                formData.append('doctor_interviewed', 0);
            }

            try {
                const endpoint = this.articleId > 0 ? apiConfig.endpoints.articleUpdate + "/" + this.articleId : apiConfig.endpoints.articleCreate;
                const method = apiConfig.methods.post;
                const response = await fetch(endpoint, {
                    method: method,
                    headers: {
                        'Authorization': 'Bearer ' + store.getToken(),
                    },
                    body: formData
                });
                const responseData = await response.json();
                if (response.ok && responseData.message === "OK") {
                    $('#summernote').summernote('reset');
                    this.resetFields();
                    this.$router.push({ path: `/admin/articles/${this.editionId}`, query: { showNotification: 'true' } });
                } else {
                    showNotification(responseData.result.error, "error", 3000);
                }
            } catch (error) {
                showNotification("Errore.", "error", 3000);
            }
        },

        async fetchTags() {
            try {
                const response = await request(apiConfig.endpoints.tagList, apiConfig.methods.get, null, store.getToken());
                
                if (response.ok) {
                    const data = await response.json();
                    this.categories = data.result.tags;
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } catch (error) {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },

        async fetchArticleTags() {
            try {
                const response = await request(apiConfig.endpoints.tagArticleList + "/" + this.articleId, apiConfig.methods.get, null, store.getToken());
                
                if (response.ok) {
                    const data = await response.json();
                    this.selectedCategories = data.result.tags;
                    
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } catch (error) {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },

        updateSidebarHeight() {
            this.$nextTick(() => {
                const contentHeight1 = this.$refs.contentWrapper.clientHeight;
                const contentHeight2 = window.innerHeight;
                const maxHeight = Math.max(contentHeight1, contentHeight2);
                const sidebar = document.querySelector('.main-sidebar');
                if (sidebar) {
                    sidebar.style.minHeight = `${maxHeight}px`;
                }
            });
        },

        async getAllDoctors() {
            const endpoint = apiConfig.noAuthEndpoints.usersByRole + "/doctor";
            const response = await request(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.dottoriDaIntervistare = data.result.usersByRole;
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },

    },

    updated() {
        this.updateSidebarHeight();
    },

    beforeDestroy() {
        if ($('#summernote').next('.note-editor').length) {
            $('#summernote').summernote('destroy');
        }
    },

    created() {
        this.articleId = this.$route.params.articleId || null;
        this.editionId = this.$route.params.editionId || null;
        this.date = this.$route.params.date || null;
    },

    async mounted() {

        if (this.articleId > 0) {
            await this.fetchArticle();
        }

        await this.getAllDoctors();
        await this.fetchTags();
        await this.fetchArticleTags();
        await this.fetchEditors();


        let vm = this; // Cattura il contesto Vue per usarlo nell'evento callback
        $('#summernote').summernote({
            placeholder: 'Scrivi qui il contenuto dell\'articolo...',
            tabsize: 2,
            height: 300,
        });

        $('.note-editable.card-block').css({
            'height': '500px', // Imposta l'altezza desiderata
            'min-height': '500px' // Imposta un'altezza minima se necessario
        });

        // Rimuovi il secondo editor Summernote, se presente
        let editors = $('.note-editor.note-frame.card');
        if (editors.length > 1) {
            $(editors[1]).remove(); // Rimuove il secondo editor
        }

        $('.select2').select2();
        $('.select2bs4').select2({
            theme: 'bootstrap4'
        });

        //imposto i valori iniziali nella select e preparo l'array selectedCategories per il submit
        let sc = [];
        this.selectedCategories.forEach(c => {
            sc.push(c.id_tag);
        });
        this.selectedCategories = [];
        sc.forEach(scId => {
            this.selectedCategories.push(scId);
        });
        $('.select2').select2().val(sc).trigger('change');
        $('.select2').select2()
            .on('change', function () {
                let selectedValues = $(this).val();
                vm.selectedCategories = selectedValues ? selectedValues : [];
            });

    },

    


}
</script>

<style scoped>


</style>