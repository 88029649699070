<template>
    <div>

        <AdminLTEMenu />

        <div class="content-wrapper" ref="contentWrapper">

            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Gestione riviste</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/admin">Home</a></li>
                                <li class="breadcrumb-item active"><a href="/admin/magazines">Gestione riviste</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">


                    <!-- Contenuto della pagina -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card">

                                <div class="card-header">
                                    <button class="btn btn-light" data-toggle="modal"
                                        data-target="#modal-edition-add">Nuova edizione</button>
                                    <button class="btn btn-light" style="margin-left: 8px" data-toggle="modal"
                                        data-target="#modal-select-edition-year">Scegli l'edizione</button>
                                </div>

                                <!-- /.card-header -->
                                <div class="card-body" v-if="editions">
                                    <div class="editions-container">
                                        <div v-for="(edition, index) in editions" :key="index" class="info-box" @click="chooseEdition(edition)">
                                            <span class="info-box-icon bg-info"><i class="far fa-newspaper"></i></span>
                                            <div class="info-box-content">
                                                <span class="info-box-text">Edizione</span>
                                                <span class="info-box-number">{{ formatDate(edition.date) }}</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>


                </div>
            </section>

        </div>

        <!-- Modal aggiunta edizione -->
        <div class="modal fade" id="modal-edition-add">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Aggiungi edizione</h4>
                        <button type="button" id="closeModalAddEdition" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="submitEdition">
                        <div class="modal-body">
                            <div class='form-group'>
                                <label>Scegli il mese e l'anno:</label>
                                <VueDatePicker v-model="editionDate" month-picker locale="it" cancelText="Annulla"
                                    selectText="Seleziona"></VueDatePicker><br />
                            </div>
                            <div class='form-group'>
                                <label>Editoriale di:</label><br>
                                <select class="form-control" v-model="editorId">
                                    <option v-for="editor in editors" :key="editor.id" :value="editor.id">
                                        {{ editor.name }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <div>
                                <button type="button" class="btn btn-default" data-dismiss="modal">Annulla</button>
                            </div>
                            <div>
                                <button type="submit" class="btn btn-primary" style="margin-left: 5px">Salva</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- MODAL: SELEZIONA ANNO PER SFOGLIARE GLI ARTICOLI-->
        <div class="modal fade" id="modal-select-edition-year">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Scegli l'edizione</h4>
                        <button type="button" id="closeModalChooseEdition" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <label>Seleziona l'edizione dell'anno:</label><br />
                        <VueDatePicker v-model="year" year-picker locale="it" cancelText="Annulla"
                            selectText="Seleziona"></VueDatePicker>
                        <div class="d-flex align-items-center" style="height: 80px;">
                            <button class="btn btn-light btn-lg mx-auto" @click="searchEdition()">Cerca...</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import apiConfig from '@/config/api';
import store from "@/store";
import { request, responseKO } from '@/config/api'
import { ref } from 'vue';
import AdminLTEMenu from '../../components/AdminComponents/AdminLTEMenu.vue'

export default {
    components: {
        AdminLTEMenu,
        VueDatePicker
    },
    data() {
        return {
            editionDate: null,
            editorId: null,
            editions: null,
            editors: null,
            year: ref(new Date().getFullYear()),
        }
    },
    created() {
        this.me();
    },
    methods: {
        chooseEdition(edition) {
            this.$router.push({
                name: 'adminArticles',
                params: {
                    editionId: edition.id_edition
                }
            });
        },
        async submitEdition() {
            if (this.editionDate === null || this.editorId === null) {
                showNotification("Attenzione. Tutti i campi sono obbligatori", "warning", 3000);
                return;
            }
            const year = this.editionDate.year;
            const m = this.editionDate.month + 1;
            const month = m < 10 ? '0' + m : m;
            const day = '01';
            const recordEditionDate = year + "-" + month + "-" + day;
            var formData = new FormData();
            formData.append('userId', this.editorId);
            formData.append('date', recordEditionDate);
            var xhr = new XMLHttpRequest();
            xhr.open('POST', apiConfig.endpoints.editionCreate, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + store.getToken());
            xhr.send(formData);
            xhr.onload = function () {
                if (xhr.status >= 200 && xhr.status < 300) {
                    const res = JSON.parse(xhr.responseText);
                    if(res.message === "OK") {
                        document.getElementById("closeModalAddEdition").click();
                        showNotification("Edizione salvata.", "success", 3000);
                        this.editionDate = null;
                        this.editorId = null;
                    } else {
                        showNotification(res.result.error, "error", 10000);
                    }
                } else {
                    showNotification("Errore durante la richiesta: " + xhr.statusText, "error", 3000);
                }
            };
            xhr.onerror = function () {
                showNotification("Errore durante la richiesta: " + xhr.statusText, "error", 3000);
            };
            this.showArticlesDiv = false;
            this.editions = [];
        },
        async fetchEditors() {
            const response = await request(apiConfig.noAuthEndpoints.editorList, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                this.editors = data.result.editors;
                if(this.editors.length == 0) {
                    showNotification("Attenzione. Non ci sono editori censiti, pertanto non potrai creare un'edizione.", "warning", 6000);    
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async searchEdition() {
            this.showArticlesDiv = false;
            const endpoint = apiConfig.endpoints.editionList + "/" + this.year;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                this.editions = [];
                this.result = true;
                const data = await response.json();
                if (data.message === "OK") {
                    if (data.result.editions && Object.keys(data.result.editions).length > 0) {
                        this.editions = data.result.editions;
                        this.year = ref(new Date().getFullYear()); //resetto l'anno
                        document.getElementById("closeModalChooseEdition").click();                 
                    } else {
                        showNotification("L'anno scelto non contiene edizioni.", "warning", 3000);
                    }
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        formatDate(dateString) {
            const options = { year: 'numeric', month: 'long' };
            const formattedDate = new Date(dateString).toLocaleDateString('it-IT', options);
            return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
        },
        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        },
        async me() {
            const response = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.user = data;
                if(!this.isObjectEmpty(this.user)) {
                    if(this.user.role != 'admin' && this.user.role != 'editor') {
                        responseKO();
                    }
                } else {
                    responseKO();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },

        updateSidebarHeight() {
            this.$nextTick(() => {
                const contentHeight1 = this.$refs.contentWrapper.clientHeight;
                const contentHeight2 = window.innerHeight;
                const maxHeight = Math.max(contentHeight1, contentHeight2);
                const sidebar = document.querySelector('.main-sidebar');
                if (sidebar) {
                    sidebar.style.minHeight = `${maxHeight}px`;
                }
            });
        },

    },

    updated() {
        this.updateSidebarHeight();
    },
    
    mounted() {
        this.fetchEditors();
    }


}
</script>

<style scoped>

    .editions-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; /* Centra gli elementi mantenendo uno spazio uniforme tra loro */
    }

    .editions-container .info-box {
        cursor: pointer; /* Cambia il cursore in un puntatore quando è sopra l'elemento */
    }

    .info-box {
        flex: 0 0 100%; /* Ogni elemento occupa l'intera larghezza del contenitore */
        margin: 5px; /* Mantiene un margine intorno agli elementi */
    }


    @media (max-width: 768px) {
        .info-box {
            /* Opzionale: Puoi modificare questo per avere meno elementi per riga su schermi più piccoli */
            flex: 0 0 calc(50% - 10px); /* Ad esempio, 2 elementi per riga su schermi piccoli */
        }
    }

  </style>