<template>
    <div>
        <Header />
        <main>
            <div id="breadcrumb" class="breadcrumb">
                <div class="container">
                    <ul>
                        <li><a href="#">InfoesteticaMag</a></li>
                        <li><a href="#">Edizioni</a></li>
                        <li><a href="#">{{ this.year }}</a></li>
                        <li><a :href="'/edition/' + this.month + '/' + this.year">{{ months[this.month] }}</a></li>
                        <li v-if="article">{{ article.title }}</li>
                    </ul>
                </div>
            </div>
            <!-- /breadcrumb -->

            <div class="container header-body-articolo" v-if="article">
                <div class="row">
                    <div class="col-md-12">
                        <div class="bloglist">
                            <h2>{{ article.title }}</h2>
                            <h4 v-if="article.subtitle">{{ article.subtitle }}</h4>
                            <div class="postmeta" v-if="article.is_interview">
                                <hr />
                                <ul>
                                    <li v-if="this.doctor && this.doctor.tag"><a href="#"><i
                                                class="icon_folder-alt"></i> {{
                            capitalizeFirstLetter(this.doctor.tag.tag) }}</a></li>
                                    <li><a href="#"><i class="icon_clock_alt"></i> {{ formatDate(article.created_at)
                                            }}</a></li>
                                    <li><a href="#"><i class="icon_pencil-edit"></i> {{ article.author.name }}</a></li>
                                </ul>
                            </div>
                            <div class="postmeta" v-else>
                                <hr />
                                <ul>
                                    <li><a href="#"><i class="icon_clock_alt"></i> {{ formatDate(article.created_at)
                                            }}</a></li>
                                    <li><a href="#"><i class="icon_pencil-edit"></i> {{ article.author.name }}</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container margin_60" v-if="article">
                <div class="row">
                    <div class="col-lg-9">
                        <div class="bloglist singlepost">

                            <!-- /post meta -->
                            <div class="post-content">
                                <div v-html="article.body"></div>
                            </div>
                            <!-- /post -->
                        </div>
                        <!-- /single-post -->

                        <!--<nav aria-label="...">
                            <ul class="pagination pagination-sm">
                                <li class="page-item">
                                    <a class="page-link" href="#" @click="navigateArticle('prev')">Articolo Precedente</a>
                                </li>
                                <li class="page-item">
                                    <a class="page-link" href="#" @click="navigateArticle('next')">Articolo Successivo</a>
                                </li>
                            </ul>
                        </nav>-->
                        <div class="page-item">
                            <button type="button" class="btn_1 medium" @click="toggleArticlesModal">Sfoglia altri articoli</button>
                        </div>



                    </div>
                    <!-- /col -->

                    <aside class="col-lg-3">

                        <div class="box_profile" v-if="this.article.doctor_interviewed > 0 && this.doctor">
                            <figure>
                                <img :src="doctor.profile_image_link === null || doctor.profile_image_link === '' ? 'https://www.placeholder.com/300x400' : doctor.profile_image_link"
                                    alt="" class="img-fluid" style="width: 306px">
                            </figure>
                            <small>{{ doctor.tag.tag }}</small>
                            <h1>{{ doctor.title }} {{ doctor.user.name }}</h1>
                            <ul class="statistic" style="display: none">
                                <li style="margin-right: 5px">854 Visualizzazioni</li>
                                <li>124 Pazienti</li>
                            </ul>
                            <ul class="contacts">
                                <li>
                                    <h6>Indirizzo</h6>{{ doctor.address }} {{ doctor.number }} - {{ doctor.cap }} {{
                            doctor.city }} ({{
                            doctor.code }})
                                </li>
                                <li>
                                    <h6>Chiama</h6><a :href="`tel:${doctor.phone}`">{{ doctor.phone }}</a>
                                </li>
                            </ul>
                            <div class="text-center"><a
                                    :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(doctor.address + ' ' + doctor.number + ' - ' + doctor.cap + ' ' + doctor.city + ' (' + doctor.code + ')')}`"
                                    target="_blank" class="btn_1 outline"><i class="icon_pin"></i> Vedi
                                    mappa</a></div>
                        </div>


                        <div class="widget" style="display: none;">
                            <form>
                                <div class="form-group">
                                    <input type="text" name="search" id="search" class="form-control"
                                        placeholder="Search...">
                                </div>
                                <button type="submit" id="submit" class="btn_1"> Search</button>
                            </form>
                        </div>
                        <!-- /widget -->

                        <div class="widget" style="display: none;">
                            <div class="widget-title">
                                <h4>Recent Posts</h4>
                            </div>
                            <ul class="comments-list">
                                <li>
                                    <div class="alignleft">
                                        <a href="#0"><img src="http://via.placeholder.com/160x160.jpg" alt=""></a>
                                    </div>
                                    <small>11.08.2016</small>
                                    <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
                                </li>
                                <li>
                                    <div class="alignleft">
                                        <a href="#0"><img src="http://via.placeholder.com/160x160.jpg" alt=""></a>
                                    </div>
                                    <small>11.08.2016</small>
                                    <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
                                </li>
                                <li>
                                    <div class="alignleft">
                                        <a href="#0"><img src="http://via.placeholder.com/160x160.jpg" alt=""></a>
                                    </div>
                                    <small>11.08.2016</small>
                                    <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
                                </li>
                            </ul>
                        </div>
                        <!-- /widget -->

                        <div class="widget" style="display: none;">
                            <div class="widget-title">
                                <h4>Blog Categories</h4>
                            </div>
                            <ul class="cats">
                                <li><a href="#">Treatments <span>(12)</span></a></li>
                                <li><a href="#">News <span>(21)</span></a></li>
                                <li><a href="#">Events <span>(44)</span></a></li>
                                <li><a href="#">New treatments <span>(09)</span></a></li>
                                <li><a href="#">Focus in the lab <span>(31)</span></a></li>
                            </ul>
                        </div>
                        <!-- /widget -->

                        <div class="widget" v-if="tags">
                            <div class="widget-title">
                                <h4>Categorie</h4>
                            </div>
                            <div class="tags">
                                <div v-for="tag in tags" style="display: inline-flex;">
                                    <a href="#" style="margin-right: 5px">{{ tag.tag_name }}</a>
                                </div>
                            </div>
                        </div>
                        <!-- /widget -->

                        

                    </aside>
                    <!-- /aside -->
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </main>
        <!-- /main -->



        <Footer />
    </div>

    <!-- cambia password modal / form-->
    <div v-if="isModalArticlesOpen && articles" class="my-modal" @click="toggleArticlesModal">
        <div class="my-modal-content">
            
            <h3>Articoli dell'edizione {{ months[this.month] }} {{ this.year }}</h3>
            <hr />
            <ul style="margin-top: 15px">
                <li v-for="article in articles" :key="article.id" style="margin-bottom: 8px">
                    <a :href="'/article/' + article.id_article + '/' + this.year + '/' + this.month" class="btn btn-light" style="padding: unset">{{ article.position }} - {{ article.title }}</a>
                </li>
            </ul>
        </div>
    </div>
</template>



<script>
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import store from "@/store";

export default {
    components: {
        Header, Footer
    },
    data() {
        return {
            id: 0,
            year: null,
            month: null,
            article: null,
            articleImageSrc: null,
            months: [
                'Non specificato',
                'Gennaio',
                'Febbraio',
                'Marzo',
                'Aprile',
                'Maggio',
                'Giugno',
                'Luglio',
                'Agosto',
                'Settembre',
                'Ottobre',
                'Novembre',
                'Dicembre'
            ],
            editor: null,
            doctor: null,
            articlesIds: [],
            isModalArticlesOpen: false,
            articles: [],
            tags:[]
        }
    },
    watch: {
        '$route'(to, from) {
            // Aggiorna l'id quando la route cambia
            this.id = to.params.id;
            this.year = to.params.year;
            this.month = to.params.month;
            this.fetchArticle();
        }
    },
    async mounted() {
        this.id = this.$route.params.id;
        this.year = this.$route.params.year;
        this.month = this.$route.params.month;
        await this.fetchArticle();
        await this.getArticlesForButton();
        await this.getDoctor();
        await this.getArticles(); //prendo tutti gli articoli di questa edizione
        await this.getArticleTags(); //prendo tutti i tag dell'articolo
    },
    methods: {
        toggleArticlesModal() {
            this.isModalArticlesOpen = !this.isModalArticlesOpen;
        },
        capitalizeFirstLetter(string) {
            return string.replace(/\b\w/g, function (char) {
                return char.toUpperCase();
            });
        },
        formatDate(dateAsString) {
            const date = new Date(dateAsString);
            const options = { day: '2-digit', month: 'long', year: 'numeric' };
            const formattedDate = date.toLocaleDateString('it-IT', options);
            return formattedDate;
        },
        async fetchArticle() {
            const endpoint = apiConfig.noAuthEndpoints.editionArticle + "/" + this.id;
            const response = await request(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.article = data.result.article;
                    this.articleImageSrc = this.article.image !== null ? this.article.image : 'https://via.placeholder.com/200x200.jpg'
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async getDoctor() {
            if (this.article && this.article.doctor_interviewed) {
                const endpoint = apiConfig.noAuthEndpoints.doctorByUserId + "/" + this.article.doctor_interviewed;
                const response = await request(endpoint, apiConfig.methods.get, null, null);
                if (response.ok) {
                    const data = await response.json();
                    if (data.message === "OK") {
                        this.doctor = data.result.doctor;
                    } else {
                        showNotification(apiConfig.errors.generic, "error", 3000)
                    }
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000)
                }
            }
        },
        async getArticlesForButton() {
            if (this.article) {
                const endpoint = apiConfig.noAuthEndpoints.editionArticlesForButton + "/" + this.article.id_edition;
                const response = await request(endpoint, apiConfig.methods.get, null, null);
                if (response.ok) {
                    const data = await response.json();
                    if (data.message === "OK") {
                        this.articlesIds = data.result.articles.map(article => article.id_article);
                    } else {
                        showNotification(apiConfig.errors.generic, "error", 3000)
                    }
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000)
                }
            }
        },
        async getArticles() {
            const endpoint = apiConfig.noAuthEndpoints.editionArticles + "/" + this.article.id_edition;
            const response = await request(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.articles = data.result.articles;
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000)
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000)
            }
        },
        async getArticleTags() {
            const endpoint = apiConfig.endpoints.tagArticleList + "/" + this.article.id_article;
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.tags = data.result.tags;
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000)
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000)
            }
        },
        navigateArticle(direction) {
            console.log(this.articlesIds)
            const currentIndex = this.articlesIds.indexOf(this.article.id_article);
            console.log(currentIndex)
            let newIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1;
            console.log(newIndex)
            // Controlla i limiti dell'array
            if (newIndex >= 0 && newIndex < this.articlesIds.length) {
                let id = this.articlesIds[newIndex];
                this.fetchArticle();
                this.updateUrl(id);
            }
        },
        updateUrl(articleId) {
            this.$router.push({ name: 'article', params: { id: articleId } });
        }
    }
}

</script>

<style scoped>
.margin_60 {
    padding-top: 5px !important;
}

h4 {
    font-size: medium;
    font-style: italic;
}

hr {
    margin-bottom: 5px;
}

.breadcrumb {
    text-align: left;
}

.my-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
}

.my-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    position: relative;
    max-height: 80%;
    overflow: overlay;
}

.close {
    color: #aaaaaa;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
</style>