import store from '@/store';

const baseUrlLocal = "http://localhost:8000/";
const baseUrlProd = "https://forumdellabellezza.com/backend/";

// *** in questo punto commenta e decommenta in base all'ambiente in uso ***
//const baseUrlDomain = baseUrlLocal;
const baseUrlDomain = baseUrlProd;
////////////////////////////////////////

const baseUrl = baseUrlDomain + "public/api";
const storageUrl = baseUrlDomain + "public/";

const fileManagerStorageUrl = baseUrlDomain+"storage/";

const apiConfig = {
  url: baseUrlDomain, 
  errors: {
    login: "Si è verificato un errore in fase di login.",
    logout: "Si è verificato un errore in fase di logout.",
    request: "Richiesta fallita.",
    generic: "Errore generico."
  },
  endpoints: {
    dashboardUser: baseUrl + "/admin/dashboard/user-count",
    dashboardComment: baseUrl + "/admin/dashboard/comment-count",
    dashboardConfirmedApp: baseUrl + "/admin/dashboard/confirmed-app-count",
    dashboardNotConfirmedApp: baseUrl + "/admin/dashboard/not-confirmed-app-count",
    userList: baseUrl+"/admin/user/list",
    userCreate: baseUrl+"/admin/user/create",
    userUpdate: baseUrl+"/admin/user/update",
    userEnable: baseUrl+"/admin/user/enable",
    userDisable: baseUrl+"/admin/user/disable",
    changePasswordUser: baseUrl+"/admin/user/password",
    editionCreate: baseUrl+"/admin/edition/create",
    editionUpdate: baseUrl+"/admin/edition/update",
    editionRead: baseUrl+"/admin/edition/read",
    editionList: baseUrl+"/admin/edition/list",
    articleCreate: baseUrl+"/admin/article/create",
    articleUpdate: baseUrl+"/admin/article/update",
    articleDelete: baseUrl+"/admin/article/delete",
    articleList: baseUrl+"/admin/article/list",
    articleRead: baseUrl+"/admin/article/read",
    filemanagerCreateFolder: baseUrl+"/filemanager/create/folder",
    filemanagerReadFolder: baseUrl+"/filemanager/read",
    filemanagerUploadFile: baseUrl+"/filemanager/upload",
    filemanagerDeleteFile: baseUrl+"/filemanager/delete",
    agendaCreate: baseUrl+"/agenda/create",
    agendaRead: baseUrl+"/agenda/read",
    agendaDelete: baseUrl+"/agenda/delete",
    doctorUpdate: baseUrl+"/doctor/update",
    doctorUpdateDoctorTag: baseUrl+"/doctor/updateDoctorTag",
    appointmentCreate: baseUrl+"/appointment/create",
    appointmentConfirm: baseUrl+"/appointment/confirm",
    appointmentList: baseUrl+"/appointment/list",
    appointmentListDoctorIdAndDate: baseUrl+"/appointment/doctor-list",
    appointmentDelete: baseUrl+"/appointment/delete",
    appointmentDeleteAll: baseUrl+"/appointment/delete-all",
    doctorTagsList: baseUrl+"/doctor/tag/list",
    comments: baseUrl+"/comments/all",
    commentsListForDoctor: baseUrl+"/comments/list",
    commentsShow: baseUrl+"/comments/show",
    commentsCreate: baseUrl+"/comments/create",
    commentsUpdate: baseUrl+"/comments/update",
    commentsDelete: baseUrl+"/comments/delete",
    commentsAverage: baseUrl+"/comments/average",
    tagCreate: baseUrl+"/admin/tag/create",
    tagUpdate: baseUrl+"/admin/tag/update",
    tagDelete: baseUrl+"/admin/tag/delete",
    tagList: baseUrl+"/admin/tag/list",
    tagArticleList: baseUrl+"/admin/tag/article-list",
    secondaryStudioAdd: baseUrl+"/doctor/add_secondary_studio",
    secondaryStudioUpdate: baseUrl+"/doctor/update_secondary_studio",
    secondaryStudioList: baseUrl+"/doctor/get_all_secondary_studios",
    secondaryStudioRead: baseUrl+"/doctor/get_single_secondary_studio",
    secondaryStudioDelete: baseUrl+"/doctor/delete_secondary_studio",
    serviceAdd: baseUrl+"/service/add",
    serviceList: baseUrl+"/service/list",
    serviceRead: baseUrl+"/service/read",
    serviceDelete: baseUrl+"/service/delete",
    serviceUpdate: baseUrl+"/service/update"
  },
  noAuthEndpoints: {
    login: baseUrl+"/login",
    logout: baseUrl+"/logout",
    me: baseUrl+"/me",
    recoveryPassword: baseUrl+"/recovery",
    recoveryChangePassword: baseUrl+"/recovery/change-password",
    editionReadByMonthYear: baseUrl+"/edition/read-by-month-year",
    editionArticles: baseUrl+"/edition/article-list",
    editionArticlesForButton: baseUrl+'/edition/article-list-for-buttons',
    editionArticle: baseUrl+"/edition/article",
    editionMonthsYearsList: baseUrl+"/edition/month-list",
    doctorList: baseUrl+"/doctors",
    doctor: baseUrl+"/doctor",
    doctorByUserId: baseUrl+"/doctor-by-user-id",
    doctorServices: baseUrl+"/doctor/services",
    agendaReadMonth: baseUrl+"/agenda/read-month",
    agendaReadDay: baseUrl+"/agenda/read-day",
    userCreate: baseUrl+"/signup/user/create",
    doctorCreate: baseUrl+"/signup/doctor/create",
    agendaMassiveDelete: baseUrl+"/agenda/massive-delete",
    editorList: baseUrl+"/editor/list",
    editorById: baseUrl+"/editor/find-by-id",
    usersByRole: baseUrl+"/users/user-by-role"
  },
  methods: {
    'get': 'GET',
    'post': 'POST',
    'put': 'PUT',
    'delete': 'DELETE'
  },
  genericHeader: {
    'Content-Type': 'application/json'
  },
  authHeaders: {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+store.getToken()
  },
  get storageUrl() {
    return storageUrl;
  },
  get fileManagerStorageUrl() {
    return fileManagerStorageUrl;
  },
};

function removeToken() {
  store.removeToken();
}

export default apiConfig;
export function redirectToHome() {
  window.location.href = "/";
}
export function responseKO() {
  removeToken();
  redirectToHome();
}
export async function request(rEndpoint, rMethod, rBody, rToken) {
  var response = null;
  try {
    $('#preloader').show();
    const requestOptions = {
      method: rMethod,
      headers: rToken !== null ? apiConfig.authHeaders : apiConfig.genericHeader,
    };
    if (rBody !== null) {
      requestOptions.body = JSON.stringify(rBody);
    }
    response = await fetch(rEndpoint, requestOptions);
    if(response === null) alert("null")
  } catch(error) {
    showNotification(apiConfig.errors.generic, "error", 3000);
    //removeToken();
    //redirectToHome();
  } finally {
    $('#preloader').hide();
  }
  return response;
}

export async function requestWihtoutPreload(rEndpoint, rMethod, rBody, rToken) {
  var response = null;
  try {
    const requestOptions = {
      method: rMethod,
      headers: rToken !== null ? apiConfig.authHeaders : apiConfig.genericHeader,
    };
    if (rBody !== null) {
      requestOptions.body = JSON.stringify(rBody);
    }
    response = await fetch(rEndpoint, requestOptions);
    if(response === null) alert("null")
  } catch(error) {
    showNotification(apiConfig.errors.generic, "error", 3000);
    removeToken();
    redirectToHome();
  }
  return response;
}

export async function requestWithFormData(rEndpoint, rMethod, rFormData, rToken) {
  var response = null;
  try {
    $('#preloader').show();
    const boundary = Date.now().toString(16);
    rFormData.append(`--${boundary}--`);
    const requestOptions = {
      method: rMethod,
      headers: rToken !== null ? apiConfig.authHeaders : apiConfig.genericHeader,
      body: rFormData,
    };
    response = await fetch(rEndpoint, requestOptions);
  } catch(error) {
    showNotification(apiConfig.errors.generic, "error", 3000);
    removeToken();
    redirectToHome();
  } finally {
    $('#preloader').hide();
  }
  return response;
}
