<template>
  <div class="cta_subscribe">
    <div class="container-fluid h-100">
      <div class="row h-100 justify-content-center align-items-center">
        <div class="col-md-6 p-0">
          <div class="block_1">
            <figure><img src="img/doctors_icon.svg" height="75" alt="" /></figure>
            <h3>Sei un medico?</h3>
            <p>
              Il servizio permette di ottenere la massima visibilità online e di gestire appuntamenti e contatti provenienti dal sito, in modo semplice e veloce.
            </p>
            <a href="/signup" class="btn_1">Iscriviti</a>
          </div>
        </div>
        <div class="col-md-6 p-0">
          <div class="block_2">
            <figure><img src="img/hospital-patient-icon.svg" height="75" /></figure>
            <h3>Sei un paziente?</h3>
            <p>
              Scegliere uno specialista non è mai stato così veloce! Puoi filtrare i risultati della ricerca per località e specializzazione medica e prenotare la visita medica online.
            </p>
            <a href="/signup" class="btn_1">Iscriviti</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

</style>