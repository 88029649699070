<template>
  <div class="home">
    <Header />
    <main>
      <Block1 />
      <!-- Modulo di ricerca -->
      <Block2 />
      <!-- Piacersi è vivere meglio -->
      <Block3 />
      <!-- Top medici infoestetica -->
      <Block4 />
      <!-- Trattamenti ed interventi -->
      <Block5 />
      <!-- Medico / Paziente -->
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import Block1 from '@/components/HomePageComponents/Block1.vue'
import Block2 from '@/components/HomePageComponents/Block2.vue'
import Block3 from '@/components/HomePageComponents/Block3.vue'
import Block4 from '@/components/HomePageComponents/Block4.vue'
import Block5 from '@/components/HomePageComponents/Block5.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView', 
  components: {
    Header, Block1, Block2, Block3, Block4, Block5, Footer
  }
}
</script>

<style scoped>
</style>