<template>
    <div>
        <AdminLTEMenu />

        <div class="content-wrapper" ref="contentWrapper">

            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Gestione categorie</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/admin">Home</a></li>
                                <li class="breadcrumb-item active">Gestione categorie</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header d-flex align-items-center">
                                    <button class="btn btn-light" data-toggle="modal" data-target="#modal-tag-add" style="width: 200px">Nuova
                                        categoria</button>
                                    <!-- Utilizza mx-2 per dare un po' di spazio tra il bottone e l'input -->
                                    <input type="text" class="form-control flex-grow-1 mx-2"
                                        placeholder="Filtra per nome..." v-model="filterQuery">
                                </div>

                                <div class="card-body">
                                    <table id="tagsTable" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Categoria</th>
                                                <th style="width: 150px;"></th>
                                                <th style="width: 150px;"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="tag in paginatedComments" :key="tag.id_tag" style="padding: 2px">
                                                <td>{{ tag.tag_name }}</td>
                                                <td class="action-column" style="cursor: pointer;">
                                                    <a data-toggle="modal" data-target="#modal-tag-edit"
                                                        @click="editPrepare(tag)"><i class="fas fa-edit"></i>
                                                        Modifica</a>
                                                </td>
                                                <td class="action-column" style="cursor: pointer;">
                                                    <a @click="removeTag(tag)"><i class="fas fa-trash"></i> Rimuovi</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                        </tfoot>
                                    </table>
                                    <pagination :total-items="tags.length" :current-page.sync="currentPage"
                                        :page-size="pageSize" @page-changed="fetchPage"></pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>

    <div class="modal fade" id="modal-tag-add">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Aggiungi categoria</h4>
                    <button type="button" id="closeModalAddTag" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <form @submit.prevent="handleSubmit">
                        <div class="form-group">
                            <label>Nome:</label>
                            <input type="text" class="form-control" required v-model="name" />
                        </div>
                    </form>

                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <div>
                        <button type="button" @click="addTag()" class="btn btn-primary">Salva</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modal-tag-edit">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Modifica categoria</h4>
                    <button type="button" id="closeModalUpdateTag" class="close" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">

                    <form @submit.prevent="handleSubmit">
                        <div class="form-group">
                            <label>Nome:</label>
                            <input type="text" class="form-control" required v-model="name" />
                        </div>
                    </form>

                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <div>
                        <button type="button" @click="editTag()" class="btn btn-primary">Salva</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import apiConfig from '@/config/api';
import store from "@/store";
import { request, responseKO } from '@/config/api'
import AdminLTEMenu from '../../components/AdminComponents/AdminLTEMenu.vue';
import Pagination from '@/views/Pagination.vue';

export default {

    components: {
        AdminLTEMenu,
        Pagination
    },

    created() {
        this.me();
    },

    data() {
        return {
            updatedTag: null,
            tags: [],
            user: null,
            name: null,
            currentPage: 1,
            pageSize: 10,
            filterQuery: '',
        }
    },

    computed: {
        paginatedComments() {
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return this.tags.filter(tag => tag.tag_name.toLowerCase().includes(this.filterQuery.toLowerCase())).slice(start, end);
        }
    },

    methods: {

        fetchPage(page) {
            this.currentPage = page;
        },

        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        },

        async me() {
            const response = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.user = data;
                if (!this.isObjectEmpty(this.user)) {
                    if (this.user.role != 'admin' && this.user.role != 'editor') {
                        responseKO();
                    }
                } else {
                    responseKO();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },

        updateSidebarHeight() {
            this.$nextTick(() => {
                const contentHeight1 = this.$refs.contentWrapper.clientHeight;
                const contentHeight2 = window.innerHeight;
                const maxHeight = Math.max(contentHeight1, contentHeight2);
                const sidebar = document.querySelector('.main-sidebar');
                if (sidebar) {
                    sidebar.style.minHeight = `${maxHeight}px`;
                }
            });
        },

        async addTag() {
            if (!this.name) {
                showNotification("Compila tutti i campi prima di aggiungere una categoria.", "warning", 3000);
                return;
            }
            const requestBody = {
                tag_name: this.name,
            };
            try {
                const response = await request(apiConfig.endpoints.tagCreate, apiConfig.methods.post, requestBody, store.getToken());
                if (response.ok) {
                    document.getElementById("closeModalAddTag").click();
                    showNotification("Categoria aggiunta con successo.", "success", 3000);
                    this.fetchTags();
                    this.name = null;
                } else {
                    showNotification("Errore nell'aggiunta dell'utente.", "error", 3000);
                }
            } catch (error) {
                showNotification("Errore nell'aggiunta dell'utente.", "error", 3000);
            }
        },

        async fetchTags() {
            try {
                const response = await request(apiConfig.endpoints.tagList, apiConfig.methods.get, null, store.getToken());
                if (response.ok) {
                    const data = await response.json();
                    this.tags = data.result.tags;
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } catch (error) {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },

        editPrepare(tag) {
            this.updatedTag = tag;
            this.name = tag.tag_name;
        },

        async editTag() {
            if (!this.name) {
                showNotification("Compila tutti i campi prima di modificare una categoria.", "warning", 3000);
                return;
            }
            const requestBody = {
                tag_name: this.name,
            };
            try {
                const response = await request(apiConfig.endpoints.tagUpdate + "/" + this.updatedTag.id_tag, apiConfig.methods.put, requestBody, store.getToken());
                if (response.ok) {
                    document.getElementById("closeModalUpdateTag").click();
                    showNotification("Categoria modificata con successo.", "success", 3000);
                    this.fetchTags();
                    this.name = null;
                    this.updatedTag = null;
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } catch (error) {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },

        async removeTag(tag) {
            if (confirm("Procedendo, tutti gli articoli associati a questo tag perderanno tale associazione. Confermi la rimozione di questa categoria?") == true) {
                const response = await request(apiConfig.endpoints.tagDelete + "/" + tag.id_tag, apiConfig.methods.delete, null, store.getToken());
                if (response.ok) {
                    const data = await response.json();
                    showNotification(data.result.message, "success", 3000);
                    this.fetchTags();
                    this.name = null;
                    this.updatedTag = null;
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            }
        }

    },

    updated() {
        this.updateSidebarHeight();
    },

    mounted() {
        this.fetchTags();
    }

}
</script>

<style scoped>
#tagsTable {
    margin-bottom: 15px;
}
</style>