<template>
  <div class="container margin_120_95">
    <div class="main_title">
      <h2>Piacersi e Vivere <strong>meglio</strong></h2>
      <p>
        Infoestetica guida l’utente verso le soluzioni per piacersi e vivere
        meglio e risponde a richieste di professionalità, innovazione,
        informazione legate al mondo dell’estetica. La
        <b>professionalità</b> certificata di medici specialisti selezionati;
        l’<b>innovazione</b> che connette medico e paziente in modo diretto e
        puntuale; l’<b>informazione</b> dettagliata e in continuo aggiornamento
        attraverso testi, immagini e video.
      </p>
    </div>
    <div class="row add_bottom_30">
      <div class="col-lg-4">
        <div class="box_feat" id="icon_1">
          <span></span>
          <h3>Trova il medico</h3>
          <p>
            Trovare il tuo medico per i trattamenti desiderati è facilissimo, 
            clicca in basso su "I nostri medici".
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box_feat" id="icon_2">
          <span></span>
          <h3>Visualizza il profilo</h3>
          <p>
            Una volta trovato il medico che volevi puoi sfogliare il suo profilo, 
            nel quale puoi vedere gli orari, prezzi, etc.
          </p>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="box_feat" id="icon_3">
          <h3>Prenota una visita!</h3>
          <p>
            Prenotare una visita non è mai stato così facile. Cerca uno slot  
            e seleziona i trattamenti desiderati!
          </p>
        </div>
      </div>
    </div>
    <p class="text-center">
      <a href="/doctors/?searchText=" class="btn_1 medium">I nostri medici</a>
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>