import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import SignupView from '../views/SignupView.vue'
import MagazineView from '../views/MagazineView.vue'
import DoctorsView from '../views/DoctorsView.vue'
import DoctorView from '../views/DoctorView.vue'
import AdminLTEDashboard from '../components/AdminComponents/AdminLTEDashboard.vue'
import AdminLTEUsers from '../components/AdminComponents/AdminLTEUsers.vue'
import AdminLTEMagazines from '../components/AdminComponents/AdminLTEMagazines.vue'
import AdminLTEFiles from '../components/AdminComponents/AdminLTEFiles.vue'
import AdminLTEArticles from '../components/AdminComponents/AdminLTEArticles.vue'
import AdminLTEArticle from '../components/AdminComponents/AdminLTEArticle.vue'
import AdminLTEComments from '../components/AdminComponents/AdminLTEComments.vue'
import AdminLTETags from '../components/AdminComponents/AdminLTETags.vue'
import NotFoundView from '../views/NotFoundView.vue'
import EditionView from '../views/EditionView.vue';
import ArticleView from '../views/ArticleView.vue';
import RecoveryView from '../views/RecoveryView.vue';
import ProfileView2 from '../views/ProfileView2.vue';
import AppointmentCreatedView from '../views/AppointmentCreatedView.vue';


const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'notfound',
    component: NotFoundView
  },
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/magazine',
    name: 'magazine',
    component: MagazineView
  },
  {
    path: '/doctors',
    name: 'doctors',
    component: DoctorsView
  },
  {
    path: '/admin/dashboard',
    name: 'adminDashboard',
    component: AdminLTEDashboard
  },
  {
    path: '/admin/files',
    name: 'adminFiles',
    component: AdminLTEFiles
  },
  {
    path: '/admin/magazines',
    name: 'adminMagazines',
    component: AdminLTEMagazines
  },
  {
    path: '/admin/tags',
    name: 'adminTags',
    component: AdminLTETags
  },
  {
    path: '/admin/articles/:editionId',
    name: 'adminArticles',
    component: AdminLTEArticles
  },
  {
    path: '/admin/article/:articleId/:editionId/:date',
    name: 'adminArticle',
    component: AdminLTEArticle
  },
  {
    path: '/admin/users',
    name: 'adminUsers',
    component: AdminLTEUsers
  },
  {
    path: '/admin/comments',
    name: 'adminComments',
    component: AdminLTEComments
  },
  {
    path: '/edition/:month/:year',
    name: 'edition',
    component: EditionView
  },
  {
    path: '/article/:id/:year/:month',
    name: 'article',
    component: ArticleView
  },
  {
    path: '/doctor/:id',
    name: 'doctor',
    component: DoctorView
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: RecoveryView
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileView2
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignupView
  },
  {
    path: '/appointment-created',
    name: 'AppointmentCreated',
    component: AppointmentCreatedView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
