<template>
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <!-- Brand Logo -->
        <a href="/admin" class="brand-link">
            <img src="/admin/dist/img/ie-fav.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3"
                style="opacity: .8">
            <span class="brand-text font-weight-light">Amministrazione</span>
        </a>

        <div class="sidebar">
            <nav class="mt-2">
                <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                    data-accordion="false">
                    <li class="nav-item menu-open">
                        <a :class="{
                            'nav-link': true, 'active': currentPath.startsWith('/admin/dashboard') && !currentPath.startsWith('/admin/users') && !currentPath.startsWith('/admin/files')
                                && !currentPath.startsWith('/admin/magazines') && !currentPath.startsWith('/admin/articles') && !currentPath.startsWith('/admin/article') && !currentPath.startsWith('/admin/comments')
                        }"
                            href="/admin/dashboard">
                            <i class="nav-icon fas fa-tachometer-alt"></i>
                            <p>
                                Dashboard
                            </p>
                        </a>
                    </li>
                    <li class="nav-item" v-if="user && user.role === 'admin'">
                        <a :class="{ 'nav-link': true, 'active': currentPath.startsWith('/admin/users') }"
                            href="/admin/users">
                            <i class="nav-icon fas fa-users"></i>
                            <p>
                                Gestione utenti
                            </p>
                        </a>
                    </li>
                    <li class="nav-item" v-if="user && user.role === 'admin'">
                        <a :class="{ 'nav-link': true, 'active': currentPath.startsWith('/admin/comments') }"
                            href="/admin/comments">
                            <i class="nav-icon fas fa-comment"></i>
                            <p>
                                Gestione commenti
                            </p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ 'nav-link': true, 'active': currentPath.startsWith('/admin/files') }"
                            href="/admin/files">
                            <i class="nav-icon fas fa-file-image"></i>
                            <p>
                                Gestione files
                            </p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ 'nav-link': true, 'active': currentPath.startsWith('/admin/tags') }"
                            href="/admin/tags">
                            <i class="nav-icon fa-solid fa-tags"></i>
                            <p>
                                Gestione categorie
                            </p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a :class="{ 'nav-link': true, 'active': currentPath.startsWith('/admin/magazines') || currentPath.startsWith('/admin/articles') || currentPath.startsWith('/admin/article') }"
                            href="/admin/magazines">
                            <i class="nav-icon fas fa-newspaper"></i>
                            <p>
                                Gestione riviste
                            </p>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="/" class="nav-link">
                            <i class="nav-icon fas fa-arrow-right"></i>
                            <p>
                                Vai al sito
                            </p>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </aside>
</template>

<script>
import apiConfig from '@/config/api';
import store from "@/store";
import { request, responseKO } from '@/config/api'

export default {

    data() {
        return {
            user: null
        }
    },

    computed: {
        currentPath() {
            return this.$route.path;
        }
    },
    methods: {
        async me() {
            const response = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.user = data;
                if (this.user === null) {
                    responseKO();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        }
    },
    created() {
        this.me();
    }

}
</script>
