<template>
    <div>
        <main>
            <div>

            </div>
            <div class="header-div">
                <div class="calendar left">
                    <button @click="changeDate('-')">Indietro</button>
                    <input type="date" ref="dateInput" class="dateInput" selected />
                    <button @click="changeDate('+')">Avanti</button>
                </div>
                <div class="right">
                    <button class="busy" v-if="newAppAvail" @click="newAppAvailToggle">Hai nuovi appuntamenti!</button>
                </div>
            </div>
            <div>
                <div class="hours" v-if="isHoursVisibile">
                    <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th style="width: 50px">Orario</th>
                                <th>Sono disponibile</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="time in times" :key="time">
                                <td class="time">{{ time }}</td>
                                <td><input type="checkbox" class="custom-checkbox" v-model="checkboxStates[time]" @change="handleCheckboxChange($event, time)" /></td>
                            </tr>
                    </tbody>
                </table>
                <div style="text-align: center;">
                    <button class="btn btn-primary" @click="submitDay"> <i class="fa fa-fw fa-plus-circle"></i> Salva la giornata </button>
                </div>
            </div>
        </div>
        <div class="modal" v-if="modalAppIsVisible">
            <div class="modal-content">
                <span class="close" @click="newAppAvailToggle">&times;</span>
                <h3 style="text-align: center;">Appuntamenti della giornata</h3>
                <ul style="margin: 15px">
                    <li v-for="appointment in appointments" :key="appointment.id" style="border: 1px solid black; margin: 15px; padding: 15px">
                        <div><b>Paziente:</b> {{ appointment.paziente }}</div>
                        <div><b>Data e ora:</b> {{ formatDate(appointment.date) }}</div>
                        <div v-if="appointment.services && appointment.services.length > 0">
                            <b>Servizi richiesti</b>:
                            <ul>
                                <li v-for="service in appointment.services">
                                    - {{ service.name }}
                                </li>
                            </ul>
                        </div>
                        <br />
                        <div v-if="appointment.confirmed == 1"><p style="color: green">L'appuntamento risulta confermato.</p></div>
                        <div v-else><p style="color: red">L'appuntamento non risulta confermato.</p></div>
                        <div style="margin-top: 10px">
                            <button @click="confirmAppointment(appointment.id, appointment.user_id, false)" class="btn btn-sm btn-danger">
                                Rifiuta
                            </button>
                            <button @click="confirmAppointment(appointment.id, appointment.user_id, true)" class="btn btn-sm btn-success" style="margin-left: 10px;">
                                Conferma
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </main>
</div></template>

<script>
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from "@/store";
import { getServices } from '@/service/service';

export default {

    props: {
        user: {
            type: Object,
            default: () => ({})
        },
        doctor: {
            type: Object,
            required: true
        }
    },
    
    data() {
        return {
            isHoursVisibile: false,
            times: ['09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00'],
            availables: [],
            notAvailables: [],
            checkboxStates: {},
            selectedDate: null,
            doctor: null,
            appointments: [],
            newAppAvail: false,
            modalAppIsVisible: false,
            services: []
        }
    },
    methods: {
        getServiceName(serviceId) {
            if (!this.services) return 'Servizio non trovato';
            const service = Object.values(this.services).find(service => service.id === parseInt(serviceId));
            return service ? service.name : 'Servizio non trovato';
        },
        async loadServices() {
            this.services = await getServices(-1);
        },
        async changeDate(operation) {
            const dateInputValue = this.$refs.dateInput.value;
            const selectedDate = new Date(dateInputValue);
            if(operation === '+') selectedDate.setDate(selectedDate.getDate() + 1);
            else selectedDate.setDate(selectedDate.getDate() - 1);
            const formattedDate = selectedDate.toISOString().split('T')[0];
            this.$refs.dateInput.value = formattedDate;
            await this.readDay();
            await this.getAppointments();
        },
        updateAvailability(event, time) {
            const isChecked = event.target.checked;
            if (isChecked) {
                // Aggiungi l'orario agli orari disponibili e rimuovilo da quelli non disponibili
                this.availables.push(time);
                this.notAvailables = this.notAvailables.filter(item => item !== time);
            } else {
                // Aggiungi l'orario agli orari non disponibili e rimuovilo da quelli disponibili
                this.notAvailables.push(time);
                this.availables = this.availables.filter(item => item !== time);
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${day}/${month}/${year} (${hours}:${minutes})`;
        },
        async submitDay() {
            this.availables = [...new Set(this.availables)];
            this.notAvailables = [...new Set(this.notAvailables)];
            //Rimuovo gli orari
            const body = {
                user_id: this.user.id,
                date: this.$refs.dateInput.value,
                times: this.notAvailables
            };
            const response = await request(
                apiConfig.endpoints.agendaDelete,
                apiConfig.methods.delete,
                body,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                if (data.message === "KO") {
                    showNotification(data.result.error, "error", 3000);
                    return;
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
                return
            }
            //Aggiungo gli orari
            const body2 = {
                user_id: this.user.id,
                date: this.$refs.dateInput.value,
                times: this.availables
            };
            const response2 = await request(
                apiConfig.endpoints.agendaCreate,
                apiConfig.methods.post,
                body2,
                store.getToken()
            );
            if (response2.ok) {
                const data2 = await response2.json();
                if (data2.message === "KO") {
                    showNotification(data2.result.error, "error", 3000);
                    return;
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
                return
            }
            showNotification("Giornata salvata correttamente.", "success", 3000); 
        },
        async readDay() {
            //se la data è inferiore a quella attuale stampa errore
            const dataAttuale = new Date();
            dataAttuale.setHours(0);
            dataAttuale.setMinutes(0);
            dataAttuale.setSeconds(0);
            const selectedDate = new Date(this.$refs.dateInput.value)
            if(selectedDate < dataAttuale) {
                showNotification("Errore. La data scelta è inferiore a quella attuale.", "error", 3000);
                this.isHoursVisibile = false;
                this.start();
                return;
            }
            //raccoglie solo date con la disponibilità uguale a TRUE
            const body = {
                user_id: this.user.id,
                date: this.$refs.dateInput.value
            };
            const response = await request(
                apiConfig.endpoints.agendaRead,
                apiConfig.methods.post,
                body,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.checkboxStates = {};
                this.availables = [];
                this.notAvailables = [...this.times];
                data.result.day.forEach((item) => {
                    const time = item.date.substring(11, 16); // Estrae l'orario nel formato HH:MM
                    this.checkboxStates[time] = true;
                    this.availables.push(time); // Aggiunge l'orario agli orari disponibili
                    const index = this.notAvailables.indexOf(time);
                    if (index > -1) {
                        this.notAvailables.splice(index, 1); // Rimuove l'orario dagli orari non disponibili
                    }
                });
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
            this.isHoursVisibile = true;
        },
        formatSelectedDateForMySQL() {
            if (!this.selectedDate) return '';
            const time = '00:00:00';
            const formattedDateTime = `${this.selectedDate} ${time}`;
            return formattedDateTime;
        },
        handleCheckboxChange(event, time) {
            const isChecked = event.target.checked;

            // Se il checkbox è spuntato, gestisci gli orari disponibili
            if (isChecked) {
                // Aggiungi a availables solo se non è già presente
                if (!this.availables.includes(time)) {
                    this.availables.push(time);
                }
                // Rimuovi da notAvailables se presente
                const notAvailableIndex = this.notAvailables.indexOf(time);
                if (notAvailableIndex !== -1) {
                    this.notAvailables.splice(notAvailableIndex, 1);
                }
            } else {
                // Gestisci gli orari non disponibili se il checkbox non è spuntato
                // Aggiungi a notAvailables solo se non è già presente
                if (!this.notAvailables.includes(time)) {
                    this.notAvailables.push(time);
                }
                // Rimuovi da availables se presente
                const availableIndex = this.availables.indexOf(time);
                if (availableIndex !== -1) {
                    this.availables.splice(availableIndex, 1);
                }
            }
        },
        sortTimes() {
            const timeToMinutes = (time) => {
                const [hours, minutes] = time.split(':').map(Number);
                return hours * 60 + minutes;
            };
            this.availables.sort((a, b) => timeToMinutes(a) - timeToMinutes(b));
            this.notAvailables.sort((a, b) => timeToMinutes(a) - timeToMinutes(b));
        },
        async getAppointments() {
            //cerca appuntamenti per doctor_id e data
            const body = {
                date: this.$refs.dateInput.value,
                doctor_id: this.doctor.id
            }
            const endpoint = apiConfig.endpoints.appointmentListDoctorIdAndDate;
            const response = await request(endpoint, apiConfig.methods.post, body, store.getToken());
            if (response && response.ok) {
                const data = await response.json();
                this.appointments = data.result.appointments;
                this.newAppAvail = this.appointments.length > 0;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000); 
            }
        },
        async getDoctor() {
            const userId = this.user.id;
            //cerco il dottore usando lo user_id
            const endpoint = apiConfig.noAuthEndpoints.doctorByUserId + "/" + this.user.id;
            const response = await request(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                this.doctor = data.result.doctor;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000); 
            }
        },
        newAppAvailToggle() {
            this.modalAppIsVisible = !this.modalAppIsVisible;
        },
        async start() {
            var today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            today = `${year}-${month}-${day}`;
            this.$refs.dateInput.value = today;
            await this.readDay();
            //cerco il dottore tramite user_id loggato (dottore loggato)
            await this.getDoctor();
            await this.getAppointments();
        },
        async confirmAppointment(app_id, user_id, isConfirmed) {
            const body = {
                confirm: isConfirmed,
                userId: user_id,
                appointmentId: app_id
            };
            const endpoint = apiConfig.endpoints.appointmentConfirm;
            const response = await request(endpoint, apiConfig.methods.post, body, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    showNotification(data.result.result, "success", 3000);
                    await this.getAppointments();                
                } else {
                    showNotification("Errore. "+data.result.error, "error", 3000);                    
                }
            } else {
                showNotification("Errore. "+data.result.error, "error", 3000); 
            }
        },
    },
    
    async mounted() {
        await this.start();
        await this.loadServices();
    }
}
</script>

<style scoped>
.title {
    margin-top: 25px;
    text-align: center;
}
.calendar {
    display: flex;
}
.dateInput {
    padding: 8px;
    font-size: larger;
}
input[type="checkbox"].custom-checkbox {
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    width: 20px; 
    height: 20px; 
    border: 2px solid #ccc; 
    border-radius: 4px; 
    outline: none; 
    cursor: pointer; 
}
input[type="checkbox"].custom-checkbox:checked {
    background-color: #007bff; 
}
input[type="checkbox"].custom-checkbox:checked::before {
    content: "\2713";
    font-size: 16px; 
    color: #fff; 
    text-align: center; 
    line-height: 20px;
}
.time {
    font-size: 16px;
}

@keyframes blink {
    0% { background-color: red; }
    10% { background-color: transparent; }
    100% { background-color: red; }
}
.busy {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    color: white;
    background-color: red;
    cursor: pointer;
    animation: blink 1s infinite;
}
.header-div {
    width: 100%;
    display: flex;
    padding: 15px 0 15px 0;
}

.left {
    flex: 1;
    text-align: left;
}

.right {
    text-align: right;
}

.modal {
  display: block; 
  position: fixed;
  z-index: 100; 
  padding-top: 150px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.8); 
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 5px;
  border: 1px solid #888;
  width: 40%;
}

.close {
  cursor: pointer;
  display: block;
  z-index: 200px;
  color: #515151;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}

.close:hover,
.close:focus {
  color: #aaaaaa;
  text-decoration: none;
  cursor: pointer;
}

</style>