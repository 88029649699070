<template>
    <div>
        <main>
            <div v-if="appointments && appointments.length > 0" class="appointments">
                <!-- Cicla la lista di appuntamenti -->
                <div v-for="(appointment, index) in appointments" :key="index" class="appointment-item">
                    <!-- Mostra le informazioni dell'appuntamento -->
                    <p v-if="appointment.confirmed == 1" style="font-size: large; color: green; margin-bottom: 8px">Appuntamento confermato!</p>
                    <p v-else style="font-size: large; color: red; margin-bottom: 8px">L'appuntamento non è stato ancora confermato.</p>
                    <p style="margin-bottom: 0px !important"><b>Data:</b> {{ formatDate(appointment.date) }}</p>
                    <p style="margin-bottom: 0px !important"><b>Dottore:</b> {{ appointment.doctorName }}</p>
                    <div v-if="appointment.services && appointment.services.length > 0">
                        <b>Servizi richiesti</b>:
                        <ul>
                            <li v-for="service in appointment.services" :key="service.id">
                                {{ service.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else><p style="margin-top: 15px; text-align: center; font-size: larger">Nessun appuntamento disponibile.</p></div>
        </main>
    </div>
</template>

<script>

import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from '@/store';

export default {

    props: {
        user: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            appointments: null
        }
    },
    
    async mounted() {
        await this.deletePreviousAppointments();
        await this.getAppointments();
    },

    methods: {
        async deletePreviousAppointments() {
            const endpoint = apiConfig.endpoints.appointmentDeleteAll;
            const response = await request(endpoint, apiConfig.methods.delete, null, store.getToken());
            const data = await response.json();
            if (response.ok) {
                if (data.message === "OK") {
                    //non faccio niente
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async getAppointments() {
            const endpoint = apiConfig.endpoints.appointmentList+"/"+this.user.id
            const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.appointments = data.result.appointments; 
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            return `${day}/${month}/${year} ${hours}:${minutes}`;
        }

    }

    

}

</script>

<style scoped>
    .appointment-item {
        margin-bottom: 10px;
        border: 1px solid #ccc;
        padding: 10px;
    }
    .appointments {
        margin-top: 15px;
    }
    .appointment-item ul {
        list-style-type: disc; /* o 'circle' per cerchi vuoti */
        margin-left: 20px; /* aggiunge spazio a sinistra per allineare i bullet points */
    }

</style>