<template>
  <div>
    <Header />
    <main>
      <div id="breadcrumb">
        <div class="container">
          <ul>
            <li><a href="#">InfoesteticaMag</a></li>
            <li><a href="#">Edizioni</a></li>
            <li><a href="#">{{ this.year }}</a></li>
            <li>{{ months[this.month] }}</li>
          </ul>
        </div>
      </div>

      <section id="header-edizione">
        <div class="container margin_60">
          <div class="row titolo-edizione">
            <div class="col-lg-12">
              <img
                :src="edition && edition.index_image ? edition.index_image : 'https://via.placeholder.com/300x300.jpg'"
                class="copertina-indice-edizione">
              <h1>Edizione <br><b>{{ months[this.month] }} {{ this.year }}</b></h1>
              <!-- <p>Usu habeo equidem sanctus no. Suas summo id sed, erat erant oporteat cu pri. In eum omnes molestie. Sed ad debet scaevola, ne mel.</p> -->
              <h5 class="label-indice">Indice</h5>
              
            </div>
          </div>
        </div>
      </section>


      <div class="container" v-if="editor">

        <div class="row">
          <!-- <div class="col-lg-9"> -->
          <div class="col-lg-12">
            <h2 class="categoria-articoli categoria-editoriale">EDITORIALE di {{ editor.name }}</h2>
            <div v-for="a in articles" :key="a.id_article">
              <article class="blog wow fadeIn">
                <div class="row g-0">
                  <div class="col-lg-3">
                    <figure>
                      <a :href="'/article/' + a.id_article + '/' + this.year + '/' + this.month">
                        <img :src="a.image ? a.image : 'https://via.placeholder.com/400x400.jpg'">
                        <div class="preview">
                          <span>Leggi l'articolo</span>
                        </div>
                      </a>
                    </figure>
                  </div>
                  <div class="col-lg-9">
                    <div class="post_info">
                      <small>{{ formatDateTime(a.created_at) }}</small>
                      <h3><a :href="'/article/' + a.id_article + '/' + this.year + '/' + this.month">{{ a.title }}</a>
                      </h3>
                      <ul>
                        <li>
                          <div class="thumb"><img
                              :src="editor && editor.small_pic ? editor.small_pic : 'https://via.placeholder.com/40x40.jpg'"
                              alt=""></div> {{ editor.name }}
                        </li>
                        <li><a :href="'/article/' + a.id_article + '/' + this.year + '/' + this.month">Leggi
                            l'articolo</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </article>

              <hr />
            </div>
          </div>


          <aside class="col-lg-3" style="display: none">
            <div class="widget">
              <form>
                <div class="form-group">
                  <input type="text" name="search" id="search" class="form-control" placeholder="Cerca nel mag...">
                </div>
                <button type="submit" id="submit" class="btn_1"> Cerca</button>
              </form>
            </div>
            <!-- /widget -->

            <div class="widget">
              <div class="widget-title">
                <h4>Recent Posts</h4>
              </div>
              <ul class="comments-list">
                <li>
                  <div class="alignleft">
                    <a href="#0"><img src="http://via.placeholder.com/160x160.jpg" alt=""></a>
                  </div>
                  <small>11.08.2016</small>
                  <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
                </li>
                <li>
                  <div class="alignleft">
                    <a href="#0"><img src="http://via.placeholder.com/160x160.jpg" alt=""></a>
                  </div>
                  <small>11.08.2016</small>
                  <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
                </li>
                <li>
                  <div class="alignleft">
                    <a href="#0"><img src="http://via.placeholder.com/160x160.jpg" alt=""></a>
                  </div>
                  <small>11.08.2016</small>
                  <h3><a href="#" title="">Verear qualisque ex minimum...</a></h3>
                </li>
              </ul>
            </div>
            <!-- /widget -->

            <div class="widget">
              <div class="widget-title">
                <h4>Blog Categories</h4>
              </div>
              <ul class="cats">
                <li><a href="#">Treatments <span>(12)</span></a></li>
                <li><a href="#">News <span>(21)</span></a></li>
                <li><a href="#">Events <span>(44)</span></a></li>
                <li><a href="#">New treatments <span>(09)</span></a></li>
                <li><a href="#">Focus in the lab <span>(31)</span></a></li>
              </ul>
            </div>
            <!-- /widget -->

            <div class="widget">
              <div class="widget-title">
                <h4>Popular Tags</h4>
              </div>
              <div class="tags">
                <a href="#">Medicine</a>
                <a href="#">Treatments</a>
                <a href="#">Events</a>
                <a href="#">Specialist</a>
                <a href="#">Pills</a>
                <a href="#">Cancer</a>
              </div>
            </div>
            <!-- /widget -->

          </aside>
          <!-- /aside -->
        </div>
        <!-- /row -->
      </div>
      <!-- /container -->
    </main>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import NotFoundView from '../views/NotFoundView.vue'

export default {
  name: 'EditionView',
  components: {
    Header, NotFoundView, Footer
  },
  data() {
    return {
      editor: null,
      storageUrl: apiConfig.storageUrl,
      month: null,
      year: null,
      edition: null,
      articles: [],
      months: [
        'Non specificato',
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre'
      ]
    }
  },
  watch: {
    '$route'(to, from) {
      this.month = to.params.month;
      this.year = to.params.year;
      this.getEdition(this.month, this.year);
    }
  },
  created() {
    // Inizializzazione iniziale
    this.month = this.$route.params.month;
    this.year = this.$route.params.year;
  },

  mounted() {
    this.start();
  },
  
  methods: {
    async start() {
      await this.getEdition(this.month, this.year);
      await this.getEditor();
      await this.getArticles();
    },
    async getEditor() {
      const endpoint = apiConfig.noAuthEndpoints.editorById + '/' + this.edition.user_id;
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.editor = data.result.editor;
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    async getEdition(month, year) {
      const endpoint = apiConfig.noAuthEndpoints.editionReadByMonthYear + "/" + month + "/" + year;
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          if (data.result.editions === null) {
            this.$router.push({ name: "notfound", params: { 'catchAll': 'notfound' } });
          } else {
            this.edition = data.result.editions;
          }
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    async getArticles() {
      const endpoint = apiConfig.noAuthEndpoints.editionArticles + "/" + this.edition.id_edition;
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.articles = data.result.articles;
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    truncateText(text, wordLimit) {
      const strippedText = text.replace(/<[^>]*>/g, '');
      const words = strippedText.split(' ');
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
      }
      return strippedText;
    },
    formatDateTime(dateTimeString) {
      const date = new Date(dateTimeString);
      const day = date.getDate().toString().padStart(2, '0');
      //const month = (date.getMonth() + 1).toString().padStart(2, '0'); // +1 perché i mesi partono da 0
      const month = this.months[date.getMonth() + 1];
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');

      return `${day} ${month} ${year}`;
    },
    gotoArticle(id) {
      this.$router.push({ name: 'article', params: { 'id': id } });
    }
  },

}
</script>

<style scoped></style>
