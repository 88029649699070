<template>
    <div>

        <AdminLTEMenu />

        <div class="content-wrapper" ref="contentWrapper">

            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Gestione utenti</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/admin">Home</a></li>
                                <li class="breadcrumb-item active">Gestione utenti</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">


                    <!-- Contenuto della pagina -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <button class="btn btn-light" data-toggle="modal" data-target="#modal-user-add"
                                        @click="clearForm(); setUser(null)">Nuovo utente</button>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table id="userTable" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Email</th>
                                                <th style="width: 150px;">Ruolo</th>
                                                <th style="width: 100px;">Abilitato</th>
                                                <th style="width: 150px;"></th>
                                            </tr>
                                            <tr>
                                                <th><input class="form-control w-100" type="text" v-model="filters.name"
                                                        placeholder="Filtra per nome..."></th>
                                                <th><input class="form-control w-100" type="text" v-model="filters.email"
                                                        placeholder="Filtra per email..."></th>
                                                <th>
                                                    <select class="form-control w-100" v-model="filters.role">
                                                        <option value="">Tutti</option>
                                                        <option value="admin">Amministratore</option>
                                                        <option value="doctor">Dottore</option>
                                                        <option value="editor">Editore</option>
                                                        <option value="user">Utente</option>
                                                        <!-- Aggiungi altre opzioni di ruolo qui -->
                                                    </select>
                                                </th>
                                                <th>
                                                    <select class="form-control w-100" v-model="filters.enabled">
                                                        <option value="">Tutti</option>
                                                        <option value="true">Sì</option>
                                                        <option value="false">No</option>
                                                    </select>
                                                </th>
                                                <th><button class="btn btn-light" @click="clearFilters()">Pulisci
                                                        filtro</button></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="user in paginatedUsers" :key="user.id" style="padding: 2px">
                                                <td>{{ user.name }}</td>
                                                <td>{{ user.email }}</td>
                                                <td>{{ showRole(user.role) }}</td>
                                                <td
                                                    v-bind:class="{ 'enabled-cell': user.enabled, 'disabled-cell': !user.enabled }">
                                                    {{ user.enabled ? 'Sì' : 'No' }}
                                                </td>
                                                <!--<td class="action-column" @click="openModal('edit', user)"> -->
                                                <td class="action-column" style="cursor: pointer;">
                                                    <a data-toggle="modal" data-target="#modal-user-edit"
                                                        @click="setUser(user)"><i class="fas fa-edit"></i> Modifica</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                        </tfoot>
                                    </table>
                                    <pagination :total-items="users.length" :current-page.sync="currentPage"
                                        :page-size="pageSize" @page-changed="fetchPage"></pagination>
                                </div>

                            </div>

                        </div>

                    </div>


                </div>
            </section>

        </div>

        <!-- Modal aggiunta utente -->
        <div class="modal fade" id="modal-user-add" v-if="user">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Aggiungi utente</h4>
                        <button type="button" id="closeModalAddUser" class="close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <form @submit.prevent="handleSubmit">
                            <div class="form-group">
                                <label>Email:</label>
                                <input type="email" class="form-control" required v-model="email" />
                            </div>
                            <div class="form-group">
                                <label>Password:</label>
                                <input type="text" class="form-control" required v-model="password" />
                            </div>
                            <div class="form-group">
                                <label>Nome completo:</label>
                                <input type="text" class="form-control" required v-model="name" />
                            </div>
                            <div v-if="role !== 'admin'">
                                <div class="form-group">
                                    <label>Ruolo:</label>
                                    <select v-model="role" class="form-control" required>
                                        <option value="editor">Editore</option>
                                        <option value="doctor">Dottore</option>
                                        <option value="user">Utente</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="role === 'doctor'">
                                <div class="form-group">
                                    <label>Link all'immagine grande del profilo del dottore:</label>
                                    <input type="text" class="form-control" v-model="profileImageLink" />
                                    <!-- <label>Link all'immagine ridotta del dottore:</label>
                                    <input type="text" class="form-control" v-model="thumbnailImageLink" /> -->
                                </div>
                            </div>
                            <div v-if="role === 'editor'">
                                <div class="form-group">
                                    <label>Link all'immagine per la firma dell'articolo:</label>
                                    <input type="text" v-model="smallImage" class="form-control" />
                                </div>
                            </div>

                        </form>

                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <div>
                            <button type="button" @click="addUser()" class="btn btn-primary">Salva</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal cambio password utente -->
        <div class="modal fade" id="modal-change-password" v-if="user" style="z-index: 9900">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Cambia password</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form @submit.prevent="handleChangePassword">
                        <div class="modal-body">
                            <div class="form-group">
                                <label>Password:</label>
                                <input type="password" class="form-control" required v-model="changePassword1" />
                            </div>
                            <div class="form-group">
                                <label>Conferma password:</label>
                                <input type="password" class="form-control" required v-model="changePassword2" />
                            </div>
                        </div>
                        <div class="modal-footer d-flex justify-content-between">
                            <div>
                                <button type="button" id="closeModalChangePassword" class="btn btn-default"
                                    data-dismiss="modal">Annulla</button>
                            </div>
                            <div>
                                <button type="submit" class="btn btn-primary" style="margin-left: 5px">Salva</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal modifica utente -->
        <div class="modal fade" id="modal-user-edit" v-if="user">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Modifica utente</h4>
                        <button type="button" id="closeModalEditUser" class="close" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                        <form @submit.prevent="handleSubmit">
                            <div class="form-group">
                                <label>Email:</label>
                                <input type="email" class="form-control" required v-model="email"
                                    :disabled="role === 'admin'" />
                            </div>
                            <div class="form-group">
                                <label>Nome completo:</label>
                                <input type="text" class="form-control" required v-model="name"
                                    :disabled="role === 'admin'" />
                            </div>
                            <div v-if="role !== 'admin'">
                                <div class="form-group">
                                    <label>Ruolo:</label>
                                    <select v-model="role" class="form-control" required>
                                        <option value="editor">Editore</option>
                                        <option value="doctor">Dottore</option>
                                        <option value="user">Utente</option>
                                    </select>
                                </div>
                            </div>
                            <div v-if="role === 'doctor'">
                                <div class="form-group">
                                    <label>Link all'immagine del profilo del dottore:</label>
                                    <input type="text" class="form-control" v-model="profileImageLink" />
                                    <!-- <label>Link all'immagine ridotta del dottore:</label>
                                    <input type="text" class="form-control" v-model="thumbnailImageLink" /> -->
                                </div>
                            </div>
                            <div v-if="role === 'editor'">
                                <div class="form-group">
                                    <label>Link all'immagine per la firma dell'articolo:</label>
                                    <input type="text" v-model="smallImage" class="form-control" />
                                </div>
                            </div>

                        </form>

                    </div>
                    <div class="modal-footer d-flex justify-content-between">
                        <div>
                            <button type="button" class="btn btn-default" data-toggle="modal"
                                data-target="#modal-change-password">Cambia password</button>
                        </div>
                        <div v-if="role != 'admin'">
                            <button type="button" @click="enableUser()" v-show="!this.user.enabled"
                                class="btn btn-default">Abilita utente</button>
                            <button type="button" @click="disableUser()" v-show="this.user.enabled"
                                class="btn btn-default">Disabilita utente</button>
                            <button type="button" @click="editUser()" class="btn btn-primary"
                                style="margin-left: 5px">Salva</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import apiConfig from '@/config/api';
import store from "@/store";
import { request, responseKO } from '@/config/api'
import AdminLTEMenu from '../../components/AdminComponents/AdminLTEMenu.vue';
import Pagination from '@/views/Pagination.vue';

export default {
    components: {
        AdminLTEMenu,
        Pagination
    },
    data() {
        return {
            users: [],
            user: null,
            doctor: null,
            name: null,
            email: null,
            role: null,
            password: null,
            smallImage: null,
            profileImageLink: null,
            thumbnailImageLink: null,
            changePassword1: '',
            changePassword2: '',
            currentPage: 1,
            pageSize: 10,
            filters: {
                name: '',
                email: '',
                role: '',
                enabled: ''
            },
        };
    },
    mounted() {
        this.fetchUsers();
    },
    created() {
        this.me();
    },
    computed: {
        paginatedUsers() {
            let filtered = this.users.filter(user => {
                return (!this.filters.name || user.name.toLowerCase().includes(this.filters.name.toLowerCase())) &&
                    (!this.filters.email || user.email.toLowerCase().includes(this.filters.email.toLowerCase())) &&
                    (!this.filters.role || user.role === this.filters.role) &&
                    (!this.filters.enabled || user.enabled.toString() === this.filters.enabled);
            });
            const start = (this.currentPage - 1) * this.pageSize;
            const end = start + this.pageSize;
            return filtered.slice(start, end);
        }
    },


    methods: {
        clearFilters() {
            this.filters.name = "";
            this.filters.email = "";
            this.filters.role = "";
            this.filters.enabled = "";
        },
        fetchPage(page) {
            this.currentPage = page;
        },
        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        },
        async me() {
            const response = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.user = data;
                if (!this.isObjectEmpty(this.user)) {
                    if (this.user.role != 'admin') {
                        responseKO();
                    }
                } else {
                    responseKO();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        clearForm() {
            this.user = null,
                this.doctor = null,
                this.name = null,
                this.email = null,
                this.password = null,
                this.role = null,
                this.profileImageLink = null,
                this.thumbnailImageLink = null,
                this.smallImage = null
        },
        async fetchUsers() {
            const response = await request(apiConfig.endpoints.userList, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                this.users = data.users;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
                responseKO();
            }
        },
        showRole(role) {
            if (role == "admin") return "Amministratore";
            if (role == "editor") return "Editore";
            if (role == "doctor") return "Dottore";
            if (role == "user") return "Utente";
        },
        async setUser(user) {
            this.user = []; //imposto a vuoto per pulire i form dei modal
            if (user !== null) {
                this.user = user;
                this.name = this.user.name;
                this.email = this.user.email;
                this.role = this.user.role;
                this.smallImage = this.user.small_pic;
                if (this.user.role === 'doctor') {
                    await this.getDoctor();
                    this.profileImageLink = this.doctor.profile_image_link;
                    this.thumbnailImageLink = this.doctor.thumbnail_image_link;
                }
            }
        },
        async enableUser() {
            try {
                const deleteEndpoint = apiConfig.endpoints.userEnable + '/' + this.user.id;
                const response = await request(deleteEndpoint, apiConfig.methods.post, null, store.getToken());
                if (response.ok) {
                    showNotification("Utente abilitato.", "success", 3000);
                    this.fetchUsers();
                    this.user.enabled = !this.user.enabled;
                } else {
                    showNotification("Errore nell'attivazione' dell'utente.", "error", 3000);
                }
            } catch (error) {
                showNotification("Errore nell'attivazione dell'utente.", "error", 3000);
            }
        },
        async disableUser() {
            try {
                const deleteEndpoint = apiConfig.endpoints.userDisable + '/' + this.user.id;
                const response = await request(deleteEndpoint, apiConfig.methods.post, null, store.getToken());
                if (response.ok) {
                    showNotification("Utente disabilitato.", "success", 3000);
                    this.fetchUsers();
                    this.user.enabled = !this.user.enabled;
                } else {
                    showNotification("Errore nella disabilitazione dell'utente.", "error", 3000);
                }
            } catch (error) {
                showNotification("Errore nella disabilitazione dell'utente.", "error", 3000);
            }
        },
        async getDoctor() {
            const endpoint = apiConfig.noAuthEndpoints.doctorByUserId + "/" + this.user.id;
            const response = await request(endpoint, apiConfig.methods.get, null, null);
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    this.doctor = data.result.doctor;
                } else {
                    showNotification(apiConfig.errors.generic, "error", 3000)
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000)
            }
        },
        async addUser() {
            // Verifica che tutti i campi siano compilati
            if (!this.name || !this.email || !this.role) {
                showNotification("Compila tutti i campi prima di aggiungere un utente.", "warning", 3000);
                return;
            }
            const requestBody = {
                name: this.name,
                email: this.email,
                role: this.role,
                password: this.password,
                profileImageLink: this.profileImageLink,
                thumbnailImageLink: this.thumbnailImageLink,
                smallImage: this.smallImage
            };
            try {
                const response = await request(apiConfig.endpoints.userCreate, apiConfig.methods.post, requestBody, store.getToken());
                if (response.ok) {
                    document.getElementById("closeModalAddUser").click();
                    showNotification("Utente aggiunto con successo.", "success", 3000);
                    this.fetchUsers();
                    this.clearForm();
                } else {
                    showNotification("Errore nell'aggiunta dell'utente.", "error", 3000);
                }
            } catch (error) {
                showNotification("Errore nell'aggiunta dell'utente.", "error", 3000);
            }
        },
        async editUser() {
            // Verifica che tutti i campi siano compilati
            if (!this.name || !this.email || !this.role) {
                showNotification("Compila tutti i campi prima di modificare l'utente.", "warning", 3000);
                return;
            }
            const requestBody = {
                name: this.name,
                email: this.email,
                role: this.role,
                profileImageLink: this.profileImageLink,
                thumbnailImageLink: this.thumbnailImageLink,
                smallImage: this.smallImage
            };
            try {
                const updateEndpoint = apiConfig.endpoints.userUpdate + '/' + this.user.id;
                const response = await request(updateEndpoint, apiConfig.methods.put, requestBody, store.getToken());
                if (response.ok) {
                    const data = await response.json();
                    if (data.message === "OK") {
                        document.getElementById("closeModalEditUser").click();
                        showNotification("Utente modificato con successo.", "success", 3000);
                        this.fetchUsers();
                        this.clearForm();

                    } else {
                        showNotification(data.message, "error", 10000)
                    }
                } else {
                    showNotification("Errore nella modifica dell'utente.", "error", 3000);
                }
            } catch (error) {
                showNotification("Errore nella modifica dell'utente.", "error", 3000);
            }
        },
        async handleChangePassword() {
            if (this.changePassword1 === this.changePassword2) {
                const requestBody = {
                    password1: this.changePassword1,
                    password2: this.changePassword2
                };
                const changePasswordEndpoint = apiConfig.endpoints.changePasswordUser + '/' + this.user.id;
                const response = await request(changePasswordEndpoint, apiConfig.methods.post, requestBody, store.getToken());
                if (response.ok) {
                    document.getElementById("closeModalChangePassword").click();
                    showNotification("La password è stata cambiata correttamente.", "success", 3000);
                    this.changePassword1 = '';
                    this.changePassword2 = '';
                } else {
                    showNotification("Errore nella modifica della password.", "error", 3000);
                }
            } else {
                showNotification("Le password non coincidono.", "warning", 3000);
            }
        },

        updateSidebarHeight() {
            this.$nextTick(() => {
                const contentHeight1 = this.$refs.contentWrapper.clientHeight;
                const contentHeight2 = window.innerHeight;
                const maxHeight = Math.max(contentHeight1, contentHeight2);
                const sidebar = document.querySelector('.main-sidebar');
                if (sidebar) {
                    sidebar.style.minHeight = `${maxHeight}px`;
                }
            });
        },

    },

    updated() {
        this.updateSidebarHeight();
    }
}

</script>

<style scoped>
.enabled-cell {
    background-color: #28a745;
    color: white;
    font-weight: bold;
}

.disabled-cell {
    background-color: #dc3545;
    color: white;
    font-weight: bold;
}

.action-column {
    text-align: center;
}

#userTable {
    margin-bottom: 15px;
}
</style>