<template>
    <div>

        <AdminLTEMenu />

        <div class="content-wrapper">

            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Gestione files</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/admin">Home</a></li>
                                <li class="breadcrumb-item active">Gestione files</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">


                    <!-- Contenuto della pagina -->

                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="fileManager">

                                    <div class="container">

                                        <div class="actions">
                                            <button id="create-folder" @click="createFolderModalToggle"><i
                                                    class="fas fa-folder"></i> Nuova cartella</button>
                                            <button onclick="document.getElementById('file-upload').click();"><i
                                                    class="fas fa-upload"></i> Carica un file</button>
                                            <input type="file" id="file-upload" style="display: none;"
                                                ref="uploadFileRef" @change="uploadFile">
                                        </div>
                                        <hr />
                                        <div class="location">
                                            <p>Posizione attuale: <b>/infoestetica/{{ actualFolder }}</b></p>
                                        </div>
                                        <div v-if="this.actualFolder !== 'app' && files && files.length == 0">
                                            <div @click="previousFolder" style="font-size: large; font-weight: bold;"><i class="fas fa-arrow-left"></i></div>
                                        </div>
                                        <table class="file-list" v-if="files && files.length > 0">
                                            <thead>
                                                <tr>
                                                    <th>Nome</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="this.actualFolder !== 'app'">
                                                    <td @click="previousFolder"
                                                        style="font-size: large; font-weight: bold;">
                                                        <i class="fas fa-arrow-left"></i>
                                                    </td>
                                                </tr>
                                                <tr v-for="file in files" :key="file.name" @click="handleFile(file)">
                                                    <td :class="{ 'directory': file.type === 'directory' }">
                                                        {{ file.type === "directory" ? file.name.toUpperCase() :
                                                file.name }}
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                    <div class="modal" v-if="isCreateFolderModalVisibile">
                                        <div class="modal-content">
                                            <span class="close" @click="createFolderModalToggle">&times;</span>
                                            <form @submit.prevent="submitNewFolder">
                                                <div class="form-group">
                                                    <input type="text" v-model="folder" class="form-control"
                                                        placeholder="Inserisci il nome della cartella..." autofocus />
                                                </div>
                                                <div class="form-group" style="text-align: center">
                                                    <button class="btn btn-success" type="submit">Salva</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div class="modal" v-if="isFileModalVisible">
                                        <div class="modal-content fileModal">
                                            <span class="close" @click="fileModalToggle">&times;</span>
                                            <div class="form-group">
                                                <label for="fileName">Indirizzo da incorporare nelle pagine:</label>
                                                <input id="fileName" type="text" readonly :value="fileUrl" />
                                            </div>
                                            <div class="form-group">
                                                
                                            </div>
                                            <div class="form-group" style="text-align: center">
                                                <button @click="removeFile" class="btn btn-danger">Rimuovi file</button>
                                                <a :href="fileUrl" target="_blank" class="btn btn-light" style="margin-left: 5px">Apri file...</a>
                                                <button @click="fileModalToggle" class="btn btn-light" style="margin-left: 5px">Chiudi</button>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>

                        </div>

                    </div>


                </div>
            </section>

        </div>

    </div>
</template>

<script>
import apiConfig from '@/config/api';
import store from "@/store";
import { request, responseKO } from '@/config/api'
import AdminLTEMenu from '../../components/AdminComponents/AdminLTEMenu.vue'

export default {
    components: {
        AdminLTEMenu
    },
    data() {
        return {
            file: null,
            folder: null,
            actualFolder: "app",
            files: [],
            isFileModalVisible: false,
            isCreateFolderModalVisibile: false
        }
    },
    created() {
        this.me();
    },
    mounted() {
        this.getAllFiles();
    },
    computed: {
        fileUrl() {
            return `${apiConfig.fileManagerStorageUrl}${this.actualFolder}/${this.file.name}`;
        }
    },
    methods: {
        previousFolder() {
            const parts = this.actualFolder.split('/');
            parts.pop();
            this.actualFolder = parts.length > 1 ? parts.join('/') : "app";
            this.getAllFiles();
        },
        createFolderModalToggle() {
            this.isCreateFolderModalVisibile = !this.isCreateFolderModalVisibile;
        },
        fileModalToggle() {
            this.isFileModalVisible = !this.isFileModalVisible;
            if (!this.isFileModalVisible) this.file = null;
        },
        async submitNewFolder() {
            if(this.folder == null) {
                showNotification("Il nome della cartella è obbligatorio.", "warning", 3000);
                return;
            }
            const body = {
                folder: this.actualFolder + '/' + this.folder.toLowerCase()
            };
            const response = await request(apiConfig.endpoints.filemanagerCreateFolder, apiConfig.methods.post, body, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    showNotification(data.result.fmResult, "success", 3000);
                } else {
                    showNotification("Errore. " + data.result.error, "error", 3000);
                }
            } else {
                showNotification("Errore.", "error", 3000);
            }
            this.getAllFiles();
            this.folder = '';
            this.createFolderModalToggle();
        },
        async getAllFiles() {
            this.files = [];
            const response = await request(apiConfig.endpoints.filemanagerReadFolder + '?folder=' + this.actualFolder, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                Object.keys(data).forEach(key => {
                    const item = data[key];
                    this.files.push(item);
                });
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        handleFile(file) {
            if (file.type === "directory") {
                this.actualFolder = this.actualFolder + "/" + file.name;

                this.getAllFiles();
            } else {
                this.file = file;
                this.isFileModalVisible = true;
                //window.open(apiConfig.fileManagerStorageUrl + this.actualFolder + '/' + file.name, '_blank');
            }
        },
        async uploadFile() {
            const fileInput = this.$refs.uploadFileRef;
            const ffile = fileInput.files[0];
            var formData = new FormData();
            formData.append('file', ffile);
            formData.append('folder', this.actualFolder);
            try {
                const response = await fetch(apiConfig.endpoints.filemanagerUploadFile, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + store.getToken()
                    },
                    body: formData
                });
                if (response.ok) {
                    showNotification("File caricato.", "success", 2500);
                } else {
                    showNotification("Errore durante la richiesta: " + response.statusText, "error", 3000);
                }
            } catch (error) {
                showNotification("Errore durante la richiesta: " + response.statusText, "error", 3000);
            }
            this.getAllFiles();
        },
        async removeFile() {
            let text = "Sei sicuro di rimuovere questo file? La rimozione del file può produrre comportamenti inattesi di visibilità nel portale.";
            if (confirm(text) == true) {
                const path = document.getElementById("fileName").value;
                const fileName = path.split('/').pop();                
                try {
                    const body = {
                        folder: this.actualFolder,
                        filename: fileName
                    };
                    const response = await request(apiConfig.endpoints.filemanagerDeleteFile, apiConfig.methods.delete, body, store.getToken());
                    if (response.ok) {
                        const data = await response.json();
                        showNotification(data.success, "success", 3000);
                        this.isFileModalVisible = false;
                    } else {
                        showNotification("Errore nella cancellazione del file.", "error", 3000);
                    }
                } catch (error) {
                    showNotification("Errore durante la richiesta: " + response.statusText, "error", 3000);
                }
                this.getAllFiles();
            }
        },
        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        },
        async me() {
            const response = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.user = data;
                if(!this.isObjectEmpty(this.user)) {
                    if(this.user.role != 'admin' && this.user.role != 'editor') {
                        responseKO();
                    }
                } else {
                    responseKO();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
    }
}
</script>

<style scoped>
.fileManager {
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
}

.actions {
    text-align: center;
}

.actions button {
    background-color: #009879;
    border: none;
    color: white;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
}

table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}

th {
    background-color: #009879;
    color: white;
    text-align: left;
    padding: 12px;
}

td {
    border-bottom: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

tr:hover {
    background-color: #f2f2f2;
    cursor: pointer;
}

.modal {
    padding: 15px 15px 15px 15px;
    display: block;
    position: fixed;
    z-index: 9990;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0);
    /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
    overflow-y: auto;
    /* Aggiunto per abilitare scrollbar verticale */
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 15px;
    border: 1px solid #888;
    width: 50%;
    max-height: 50%;
    overflow-y: auto;
    /* Aggiunto per abilitare scrollbar verticale */
}

.fileModal {
    width: 60%;
}

.close {
    cursor: pointer;
    display: block;
    z-index: 200px;
    color: #515151;
    text-align: right;
    font-size: 28px;
    font-weight: bold;
    margin-right: 10px;
}

.close:hover,
.close:focus {
    color: #aaaaaa;
    text-decoration: none;
    cursor: pointer;
}

input {
    width: 100%;
}

.directory {
    font-size: large;
    font-weight: bold;
}

.location {
    width: 100%;
    padding: 15px 15px 0 10px;
    border: 1px solid lightgray;
    margin-bottom: 15px;
}
</style>