<template>
  <div>
    <Header />
    <main>
      <div id="results">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h4><strong>Risultati {{ size }}</strong> di {{ totalCount }}</h4>
            </div>
            <div class="col-md-6">
              <div class="search_bar_list">
                <form @submit.prevent="search">
                  <input type="text" class="form-control" v-model="searchText"
                    placeholder="Cerca per nome, trattamento, città o lascia vuoto per cercare tutti...">
                  <input type="submit" value="Cerca">
                </form>
              </div>
            </div>
          </div>
          <!-- /row -->
        </div>
        <!-- /container -->
      </div>
      <!-- /results -->

      <div class="filters_listing">
        <div class="container">
          <ul class="clearfix">
            <li>
              <h6>Filtro</h6>
              <div class="switch-field">
                <input type="radio" id="all" name="type_patient" value="all" @click="filterTagDoctors('all')" checked>
                <label for="all">Tutti</label>
                <input type="radio" id="doctors" name="type_patient" value="doctors">
                <label for="doctors" @click="filterTagDoctors('chirurgo plastico')">Chirurghi Plastici</label>
                <input type="radio" id="clinics" name="type_patient" value="clinics"
                  @click="filterTagDoctors('medico estetico')">
                <label for="clinics">Medici Estetici</label>
                <input type="radio" id="odontoiatra" name="type_patient" value="odontoiatra"
                  @click="filterTagDoctors('odontoiatra')">
                <label for="odontoiatra">Odontoiatra</label>
                <input type="radio" id="altro" name="type_patient" value="altro" @click="filterTagDoctors('altro')">
                <label for="altro">Altro</label>

                <input type="radio" id="priceAsc" name="type_patient_by_price" value="priceAsc"
                  @click="sortDoctorsByPrice('asc')" ref="priceAsc">
                <label for="priceAsc" title="Dal prezzo più basso a quello più alto">Prezzo <i
                    class="fa-solid fa-arrow-up"></i> </label>
                <input type="radio" id="priceDesc" name="type_patient_by_price" value="priceDesc"
                  @click="sortDoctorsByPrice('desc')">
                <label for="priceDesc" title="Dal prezzo più alto a quello più basso">Prezzo <i
                    class="fa-solid fa-arrow-down"></i></label>
                
                <br />    
                <select class="form-select" style="width: auto;" @change="filterByProvince">
                  <option value="">Filtra per provincia...</option>
                  <option v-for="(name, code) in provinces" :key="code"
                      :value="code">{{ name }} ({{ code }})</option>
                </select>

              </div>
            </li>

            <!--<li>
              <h6>Vedi altri filtri</h6>
              <select name="orderby" class="selectbox">
                <option value="Closest">Vicinanza</option>
                <option value="Best rated">Popolarità</option>
                <option value="Men">Uomo</option>
                <option value="Women">Donna</option>
              </select>
            </li>-->
          </ul>
        </div>
        <!-- /container -->
      </div>
      <!-- /filters -->

      <div class="container margin_60_35">
        <div class="row">

          <h2 style="text-align: center" v-if="filteredDoctors.length == 0">Nessun dottore trovato.</h2>

          <div class="col-lg-12" v-if="filteredDoctors.length > 0">

            <h2 style="text-align: center">Risultati della ricerca</h2>

            <nav aria-label="Navigazione paginazione" class="add_top_20">
              <ul class="pagination pagination-sm">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Pagina Precedente</a>
                </li>
                <li class="page-item" v-for="page in pages" :key="page" :class="{ active: currentPage === page }">
                  <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === pages }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Pagina Successiva</a>
                </li>
              </ul>
            </nav>

            <!-- /Schedina dottore -->
            <div v-for="d in filteredDoctors" :key="d.id">
              <div class="strip_list">

                <div class="row">

                  <!--<div class="col-lg-7">-->
                  <div class="col-lg-12">
                    <figure>
                      <a :href="`/doctor/${d.id}`">
                        <img
                          :src="d.profile_image_link && d.profile_image_link.length > 0 ? d.profile_image_link : 'https://via.placeholder.com/80x80'"
                          alt="" class="doctor-image">
                      </a>

                    </figure>
                    <small>{{ d.tag.tag }}</small>
                    <h3>
                      {{ d.title }} {{ d.user.name }}
                    </h3>
                    <p>{{ d.address }} {{ d.number }} - {{ d.cap }} {{ d.city }} ({{ d.code }})</p>
                    <span class="rating">
                      <i v-for="star in 5" :key="star" class="icon_star"
                        :class="{ voted: star <= averages[d.user.id] || 0 }"></i>
                    </span>
                    <a href="badges.html" data-bs-toggle="tooltip" data-bs-placement="top" title="Badge Level"
                      class="badge_list_1">
                      <img src="/img/badges/badge_1.svg" width="15" height="15" alt="">
                    </a>
                    <ul>
                      <li><a
                          :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(d.address + ' ' + d.number + ' - ' + d.cap + ' ' + d.city + ' (' + d.code + ')')}`"
                          target="_blank" class="btn_listing">Vedi sulla mappa</a></li>
                      <li><a :href="`tel:${d.phone}`">Chiama</a></li>
                      <li><a :href="`/doctor/${d.id}`">Visualizza profilo</a></li>
                    </ul>
                  </div>
                  <div class="col-lg-5" style="display: none">
                    <form>
                      <div class="title">
                        <h3>Prenota la tua visita</h3>
                        <small>Lunedì - Venerdì | 09.00 - 18.00</small>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <label for="meeting-time">Scegli la data l'ora:</label>
                            <input type="datetime-local" id="meeting-time" name="meeting-time" value="2024-01-12T19:30"
                              min="2018-06-07T00:00" max="2018-06-14T00:00" />
                          </div>
                        </div>
                      </div>
                      <a :href="`/doctor/${d.id}`" class="btn_1 full-width">Prenota la tua visita</a>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <nav aria-label="Navigazione paginazione" class="add_top_20">
              <ul class="pagination pagination-sm">
                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage - 1)">Pagina Precedente</a>
                </li>
                <li class="page-item" v-for="page in pages" :key="page" :class="{ active: currentPage === page }">
                  <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                </li>
                <li class="page-item" :class="{ disabled: currentPage === pages }">
                  <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Pagina Successiva</a>
                </li>
              </ul>
            </nav>



          </div>

        </div>

      </div>

    </main>
    <Footer />
  </div>
</template>

<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import apiConfig from '@/config/api';
import { request } from '@/config/api';

export default {
  name: 'DoctorsView',
  components: {
    Header, Footer
  },
  data() {
    return {
      doctors: [],
      filteredDoctors: [],
      size: 0,
      totalCount: 0,
      pages: 0,
      offset: 0,
      currentPage: 1,
      searchText: "",
      averages: {},
      provinces: {
        AG: 'Agrigento',
        AL: 'Alessandria',
        AN: 'Ancona',
        AO: 'Aosta',
        AR: 'Arezzo',
        AP: 'Ascoli Piceno',
        AT: 'Asti',
        AV: 'Avellino',
        BA: 'Bari',
        BT: 'Barletta-Andria-Trani',
        BL: 'Belluno',
        BN: 'Benevento',
        BG: 'Bergamo',
        BI: 'Biella',
        BO: 'Bologna',
        BZ: 'Bolzano',
        BS: 'Brescia',
        BR: 'Brindisi',
        CA: 'Cagliari',
        CL: 'Caltanissetta',
        CB: 'Campobasso',
        CI: 'Carbonia-Iglesias',
        CE: 'Caserta',
        CT: 'Catania',
        CZ: 'Catanzaro',
        CH: 'Chieti',
        CO: 'Como',
        CS: 'Cosenza',
        CR: 'Cremona',
        KR: 'Crotone',
        CN: 'Cuneo',
        EN: 'Enna',
        FM: 'Fermo',
        FE: 'Ferrara',
        FI: 'Firenze',
        FG: 'Foggia',
        FC: 'Forlì-Cesena',
        FR: 'Frosinone',
        GE: 'Genova',
        GO: 'Gorizia',
        GR: 'Grosseto',
        IM: 'Imperia',
        IS: 'Isernia',
        SP: 'La Spezia',
        AQ: 'L\'Aquila',
        LT: 'Latina',
        LE: 'Lecce',
        LC: 'Lecco',
        LI: 'Livorno',
        LO: 'Lodi',
        LU: 'Lucca',
        MC: 'Macerata',
        MN: 'Mantova',
        MS: 'Massa-Carrara',
        MT: 'Matera',
        VS: 'Medio Campidano',
        ME: 'Messina',
        MI: 'Milano',
        MO: 'Modena',
        MB: 'Monza e Brianza',
        NA: 'Napoli',
        NO: 'Novara',
        NU: 'Nuoro',
        OG: 'Ogliastra',
        OT: 'Olbia-Tempio',
        OR: 'Oristano',
        PD: 'Padova',
        PA: 'Palermo',
        PR: 'Parma',
        PV: 'Pavia',
        PG: 'Perugia',
        PU: 'Pesaro e Urbino',
        PE: 'Pescara',
        PC: 'Piacenza',
        PI: 'Pisa',
        PT: 'Pistoia',
        PN: 'Pordenone',
        PZ: 'Potenza',
        PO: 'Prato',
        RG: 'Ragusa',
        RA: 'Ravenna',
        RC: 'Reggio Calabria',
        RE: 'Reggio Emilia',
        RI: 'Rieti',
        RN: 'Rimini',
        RM: 'Roma',
        RO: 'Rovigo',
        SA: 'Salerno',
        SS: 'Sassari',
        SV: 'Savona',
        SI: 'Siena',
        SR: 'Siracusa',
        SO: 'Sondrio',
        TA: 'Taranto',
        TE: 'Teramo',
        TR: 'Terni',
        TO: 'Torino',
        TP: 'Trapani',
        TN: 'Trento',
        TV: 'Treviso',
        TS: 'Trieste',
        UD: 'Udine',
        VA: 'Varese',
        VE: 'Venezia',
        VB: 'Verbano-Cusio-Ossola',
        VC: 'Vercelli',
        VR: 'Verona',
        VV: 'Vibo Valentia',
        VI: 'Vicenza',
        VT: 'Viterbo',
      },
      selectedProvince: "",
    }
  },
  mounted() {
    this.searchText = this.$route.query.searchText;
    this.getAllDoctors();
  },
  methods: {
    // async getAllDoctors() {
    //   var text = "__all__";
    //   if(this.searchText !== "") text = this.searchText;
    //   const endpoint = apiConfig.noAuthEndpoints.doctorList;
    //   const body = {
    //     offset: this.offset,
    //     searchText: text
    //   };
    //   const response = await request(endpoint, apiConfig.methods.post, body, null);
    //   if (response.ok) {
    //     const data = await response.json();
    //     if (data.message === "OK") {
    //       this.totalCount = data.result.totalCount;
    //       this.size = text !== "__all__" ? data.result.size : this.totalCount;
    //       this.pages = Math.ceil(this.size / 5);
    //       this.doctors = data.result.doctors;
    //       this.filteredDoctors = this.doctors;
    //     } else {
    //       showNotification(data.result.error, "error", 3000);
    //     }
    //   } else {
    //     showNotification(apiConfig.errors.generic, "error", 3000);
    //   }
    // },
    sortDoctorsByPrice(order) {
      this.priceSortOrder = order;
      this.filteredDoctors.sort((a, b) => {
        // Calcola la media dei prezzi dei servizi per il dottore 'a'
        const pricesA = a.services.map(service => parseFloat(service.price));
        const avgPriceA = pricesA.length ? pricesA.reduce((sum, price) => sum + price, 0) / pricesA.length : 0
        // Calcola la media dei prezzi dei servizi per il dottore 'b'
        const pricesB = b.services.map(service => parseFloat(service.price));
        const avgPriceB = pricesB.length ? pricesB.reduce((sum, price) => sum + price, 0) / pricesB.length : 0;
        return order === 'asc' ? avgPriceA - avgPriceB : avgPriceB - avgPriceA;
      });
    },

    filterTagDoctors(value) {
      if (value === "all") {
        this.filteredDoctors = this.doctors;
      } else {
        this.filteredDoctors = this.doctors.filter(doctor => doctor.tag.tag === value);
      }
    },

    filterByProvince(event) {
      const selectedProvince = event.target.value;
      this.selectedProvince = selectedProvince;
      if (selectedProvince === "") {
        // Se "Filtra per vicinanza..." è selezionato, mostra tutti i dottori
        this.filteredDoctors = this.doctors;
      } else {
        // Filtra i dottori per provincia
        this.filteredDoctors = this.doctors.filter(doctor => doctor.code === selectedProvince);
      }
    },

    changePage(page) {
      if (page < 1 || page > this.pages) return;
      const pageSize = 5;
      this.offset = (page - 1) * pageSize;
      this.currentPage = page;
      this.getAllDoctors();
    },
    search() {
      this.doctors = [],
        this.filteredDoctors = [],
        this.size = 0,
        this.totalCount = 0,
        this.pages = 0,
        this.offset = 0,
        this.currentPage = 1,
        this.getAllDoctors();
    },

    async getAllDoctors() {
      var text = "__all__";
      if (this.searchText !== "") text = this.searchText;
      const endpoint = apiConfig.noAuthEndpoints.doctorList;
      const body = {
        offset: this.offset,
        searchText: text
      };
      const response = await request(endpoint, apiConfig.methods.post, body, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.totalCount = data.result.totalCount;
          this.size = text !== "__all__" ? data.result.size : this.totalCount;
          this.pages = Math.ceil(this.size / 5);
          this.doctors = data.result.doctors;
          this.filteredDoctors = this.doctors;

          console.log(this.doctors)
          // Carica le medie delle stelle per ciascun dottore
          await Promise.all(this.filteredDoctors.map(async doctor => {
            const avgStars = await this.getAverageDoctor(doctor.user.id);
            this.averages[doctor.user.id] = avgStars;
          }));
          this.$refs.priceAsc.click(); // fa in modo di filtrare subito dal prezzo più economico a quello più alto
        } else {
          showNotification(data.result.error, "error", 3000);
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
      }
    },

    async getAverageDoctor(userId) {
      const endpoint = apiConfig.endpoints.commentsAverage + "/" + userId;
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          return data.result.average_stars;
        }
      }
      return 0;
    },

  },

}
</script>

<style scoped>
.doctor-image {
  width: 80px;
  height: 80px;
}
</style>