<template>
  <div>
    <header class="header_sticky">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-6">
            <div id="logo_home">
              <h1><a href="/" title="Infoestetica">Infoestetica</a></h1>
            </div>
          </div>
          <nav class="col-lg-9 col-6">
            <a
              class="cmn-toggle-switch cmn-toggle-switch__htx open_close"
              href="#0"
              ><span>Menu mobile</span></a
            >
            <ul id="top_access" v-if="!isLogged">
              <li>
                <span @click="loginToggle">
                  <i class="pe-7s-user"></i>
                </span>
              </li>
              <li>
                <span>
                  <a href="/signup"><i class="pe-7s-add-user"></i></a>
                </span>
              </li>
            </ul>
            <div class="main-menu">
              <ul>
                <li><a href="/">Home</a></li>
                <li>
                  <a class="show-submenu">Magazine<i class="icon-down-open-mini"></i></a>
                <ul style="min-width: 100px">
                  <li class="third-level" v-for="(mesi, anno) in anniConMesi" :key="anno">
                    <a href="#0">{{ anno }}</a>
                    <ul>
                      <li v-for="mese in mesi" :key="mese">
                        <a href="#0" @click="gotoMagazine(mese, anno)">{{ meseNome(mese) }}</a>
                      </li>
                    </ul>
                  </li>
                </ul>
                </li>
                <li>
                  <a href="/doctors/?searchText=">I nostri dottori</a>
                </li>
                <li v-if="isLogged && user.role !== 'admin'">
                  <a href="/profile">Profilo</a>
                </li>
                <li v-if="isAdmin">
                  <a href="/admin/dashboard">Amministrazione</a>
                </li>
                <li v-if="isLogged"><a @click="logout" href="#">Esci</a></li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
    <div class="modal" v-if="isVisible">
      <div class="modal-content">
        <span class="close" @click="loginToggle">&times;</span>
        <LoginView />
      </div>
    </div>
  </div>
  
</template>

<script>
import store from "@/store";
import apiConfig from '@/config/api';
import { request, requestWihtoutPreload, responseKO } from '@/config/api';
import LoginView from "@/views/LoginView.vue";
import router from '@/router'; 


export default {
  data() {
    return {
      user: null,
      isAdmin: false,
      isLogged: false,
      isVisible: false,
      anniConMesi: {}
    };
  },
  components: {
    LoginView
  },
  methods: {
    loginToggle() {
      this.isVisible = !this.isVisible;
      $(".header_sticky").toggle();
    },
    meseNome(mese) {
      const nomiMesi = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'];
      return nomiMesi[mese - 1];
    },
    gotoMagazine(month, year) {
        router.push({ name: 'edition', params: { 'month': month, 'year': year } });
    },
    async monthList() {
      const response = await requestWihtoutPreload(
        apiConfig.noAuthEndpoints.editionMonthsYearsList,
        apiConfig.methods.get,
        null,
        null
      );
      if (response.ok) {
        const data = await response.json();
        this.anniConMesi = data.result.months;
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
      }
    },
    async me() {
      const response = await requestWihtoutPreload(
        apiConfig.noAuthEndpoints.me,
        apiConfig.methods.post,
        null,
        store.getToken()
      );
      if (response.ok) {
        const data = await response.json();
        this.user = data;
        if(this.user.role  !== undefined) {
          this.isLogged = true
          this.isAdmin = this.user.role == "admin" || this.user.role == "editor";
        } else {
          this.isLogged = false
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
        responseKO();
      }
    },
    async logout() {
      await request(apiConfig.noAuthEndpoints.logout, apiConfig.methods.post, null, store.getToken());
      responseKO();
    }
  },
  beforeMount() {
    if(store.hasToken()) { 
      this.me();
    }
  },
  mounted() {
    // Menu
    $("a.open_close").on("click", function () {
      $(".main-menu").toggleClass("show");
      $(".layer").toggleClass("layer-is-visible");
      $("header.static").toggleClass("header_sticky sticky");
      $("body").toggleClass("body_freeze");
    });
    $("a.show-submenu").on("click", function () {
      $(this).next().toggleClass("show_normal");
    });
    // Hamburger icon
    var toggles = document.querySelectorAll(".cmn-toggle-switch");
    for (var i = toggles.length - 1; i >= 0; i--) {
      var toggle = toggles[i];
      toggleHandler(toggle);
    }
    function toggleHandler(toggle) {
      toggle.addEventListener("click", function (e) {
        e.preventDefault();
        this.classList.contains("active") === true
          ? this.classList.remove("active")
          : this.classList.add("active");
      });
    }
    this.monthList();
  },
};

</script>

<style scoped>
/*
.chooseMagazine {
  padding: 15px;
  text-align: center;
}
.chooseMagazine label {
  text-align: left;
}
.chooseMagazine button {
  margin-top: 10px;
}

.chooseMagazineError {
  color: red;
}

.chooseMagazineModal {
  width: 500px;
  max-width: 500px;
}
*/
.pe-7s-user {
  top: -10px;
  font-size: 2.125rem;
}
.pe-7s-user:hover {
  cursor: pointer;
}
header.header_sticky {
  height: 57px;
  /*overflow: hidden; tenere commentato */
}

.modal {
  display: block; 
  position: fixed;
  z-index: 100; 
  padding-top: 30px;
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); 
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 5px;
  border: 1px solid #888;
  width: 60%;
}

.close {
  cursor: pointer;
  display: block;
  z-index: 200px;
  color: #515151;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}

.close:hover,
.close:focus {
  color: #aaaaaa;
  text-decoration: none;
  cursor: pointer;
}

a {
  cursor: pointer;
}

</style>