<template>
  <div v-if="doctor && doctor.tag">
    <Header />
    <main>
      <div id="breadcrumb">
        <div class="container" v-if="doctor">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/doctors">I nostri medici</a></li>
            <li>{{ doctor.title }} {{ doctor.user.name }}</li>
          </ul>
        </div>
      </div>
      <!-- /breadcrumb -->
      <div class="container margin_60">
        <div class="row">
          <aside class="col-xl-3 col-lg-4" id="sidebar" v-if="doctor">
            <div class="box_profile">
              <figure>
                <img
                  :src="doctor.profile_image_link === null || doctor.profile_image_link === '' ? 'https://www.placeholder.com/300x400' : doctor.profile_image_link"
                  alt="" class="img-fluid" style="width: 306px">
              </figure>
              <small>{{ doctor.tag.tag }}</small>
              <h1>{{ doctor.title }} {{ doctor.user.name }}</h1>
              <span class="rating">
                <i v-for="star in 5" :key="star" class="icon_star" :class="{ voted: star <= average }"></i>
                <a href="badges.html" data-bs-toggle="tooltip" data-bs-placement="top" title="Badge Level"
                  class="badge_list_1">
                  <img src="/img/badges/badge_1.svg" width="15" height="15" alt="">
                </a>
              </span>
              <!-- <ul class="statistic">
                <li>854 Visualizzazioni</li>
                <li>124 Pazienti</li>
              </ul>-->
              <ul class="contacts">
                <li>
                  <h6>Indirizzo Primario</h6>{{ doctor.address }} {{ doctor.number }} - {{ doctor.cap }} {{ doctor.city }} ({{
    doctor.code }})
                </li>
                <li>
                  <h6>Chiama</h6><a :href="`tel:${doctor.phone}`">{{ doctor.phone }}</a>
                </li>
                <li>
                  <button @click="ssModalToggle()" style="padding-left: 0px" class="btn btn-sm btn-link">Vedi altri studi del dottore</button>
                </li>
              </ul>
              <div class="text-center"><a
                  :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(doctor.address + ' ' + doctor.number + ' - ' + doctor.cap + ' ' + doctor.city + ' (' + doctor.code + ')')}`"
                  target="_blank" class="btn_1 outline"><i class="icon_pin"></i> Vedi
                  mappa</a></div>
            </div>
          </aside>
          <!-- /asdide -->

          <div class="col-xl-9 col-lg-8">

            <div class="box_general_2 add_bottom_45">
              <div class="main_title_4">
                <h3><i class="icon_circle-slelected"></i>Seleziona data e ora</h3>
              </div>

              <div class="row add_bottom_45">
                <div class="col-lg-7">
                  <div class="form-group">
                    <!-- stampo il calendario -->
                    <div id="calendar"></div>
                    <input type="hidden" id="my_hidden_input">
                    <ul class="legend">
                      <li><strong></strong>Disponibile</li>
                      <li><strong></strong>Non disponibile</li>
                    </ul>
                  </div>
                </div>
                <!-- stampo gli orari disponibili -->
                <div class="col-lg-5">
                  <p v-if="availableTimes.length > 0"><b>Orari disponibili del dottore per il giorno {{
    formatDate(selectedDate) }}</b></p>
                  <p v-else><b>Il dottore non ha orari per questa giornata</b></p>
                  <ul class="time_select version_2 add_top_20">
                    <li v-for="availableTime in availableTimes" :key="availableTime">
                      <input type="radio" :id="`radio${availableTime.replace(':', '.')}`" name="radio_time"
                        :value="availableTime.replace(':', '.')" @change="selectedTime = availableTime">
                      <label :for="`radio${availableTime.replace(':', '.')}`">{{ availableTime }}</label>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- /row -->

              <div class="main_title_4">
                <h3><i class="icon_circle-slelected"></i>Seleziona trattamento</h3>
              </div>
              <ul class="treatments clearfix">
                <div v-for="s in services" :key="s.id">
                  <li>
                    <div class="checkbox">
                      <input type="checkbox" class="css-checkbox" :id="'service' + s.id" :name="'service' + s.id"
                        v-model="s.selected">
                      <label :for="'service' + s.id" class="css-label">{{ s.name }} <strong>€{{ s.price !== null
    ? s.price : '0.00' }}</strong></label>
                    </div>
                  </li>
                </div>
              </ul>
              <hr>
              <div class="text-center"><button class="btn_1 medium" @click="prenota()">Prenota</button></div>
            </div>
            <!-- /box_general -->

            <div class="tabs_styled_2">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="general-tab" data-bs-toggle="tab" href="#general" role="tab"
                    aria-controls="general" aria-expanded="true">Info Generali</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="reviews-tab" data-bs-toggle="tab" href="#reviews" role="tab"
                    aria-controls="reviews">Recensioni</a>
                </li>
              </ul>
              <!--/nav-tabs -->

              <div class="tab-content">
                <div class="tab-pane fade show active" id="general" role="tabpanel" aria-labelledby="general-tab">

                  <div v-html="this.doctor.curriculum"></div>


                </div>
                <!-- /tab_2 -->

                <div class="tab-pane fade" id="reviews" role="tabpanel" aria-labelledby="reviews-tab">
                  <div class="reviews-container">
                    <!-- Genera dinamicamente i commenti qui -->
                    <p v-if="comments.length === 0" class="empty-comments">La lista dei commenti è vuota</p>
                    <div class="review-box clearfix" style="padding-left: unset;" v-for="comment in comments"
                      :key="comment.id">
                      <figure class="rev-thumb" style="display: none;"><img
                          :src="comment.avatar || 'http://via.placeholder.com/150x150.jpg'" alt="User image">
                      </figure>
                      <div class="rev-content">
                        <div class="rating">
                          <i class="icon_star" v-for="star in 5" :class="{ voted: star <= comment.stars }"></i>
                        </div>
                        <div class="rev-info">
                          {{ comment.author_name }} – {{ formatDateFromString(comment.created_at) }}
                        </div>
                        <div class="rev-text">
                          <p>{{ comment.comment }}</p>
                          <button v-if="comment.author_id == user.id || this.user && this.user.role == 'admin'"
                            @click="deleteComment(comment.id)" class="btn btn-light btn-sm" style="margin-bottom:8px">
                            [ Rimuovi commento ]
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- End review-container -->
                  <hr>
                  <div class="text-end" v-if="this.user && this.user.role == 'user'"><a class="btn_1 add_bottom_15"
                      @click="modalToggle()">Scrivi una recensione</a>
                  </div>
                </div>
                <!-- /tab_3 -->
              </div>
              <!-- /tab-content -->
            </div>
            <!-- /tabs_styled -->
          </div>
          <!-- /col -->
        </div>
        <!-- /row -->
      </div>
      <!-- /container -->

    </main>
    <!-- /main -->
    <Footer />
  </div>
  <div class="modal" v-if="isModalVisible">
    <div class="modal-content">

      <div>
        <h4 class="modal-title" style="float: left;">Nuova recensione</h4>
        <span class="close" @click="modalToggle">&times;</span>
        <hr>
      </div>
      <form @submit.prevent="submitComment()">
        <div class="form-group">
          <label>Voto</label>
          <select class="form-control" v-model="modelStars">
            <option value="1">1 stella</option>
            <option value="2">2 stelle</option>
            <option value="3">3 stelle</option>
            <option value="4">4 stelle</option>
            <option value="5" selected>5 stelle</option>
          </select>
        </div>
        <div class="form-group">
          <label>Recensione</label>
          <textarea rows="5" class="form-control" v-model="modelComment"></textarea>
        </div>
        <div class="form-group">
          <button type="submit" class="btn btn-primary">Salva</button>
        </div>
      </form>
    </div>
  </div>

  <div class="modal" v-if="isSSModalVisible" @click="">
    <div class="modal-content">

      <div>
        <h4 class="modal-title" style="float: left;">Altri studi</h4>
        <span class="close" @click="ssModalToggle">&times;</span>
        <hr>

        <ul v-if="ss">
          <li v-for="studio in ss"> - <b>{{ studio.nome_studio }}</b>, {{ studio.address }} {{ studio.number}} - {{  studio.cap }} {{ studio.city }} ({{ studio.code }})</li>
        </ul>
      </div>
    </div>
  </div>

</template>

<script>

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import apiConfig from '@/config/api';
import { request } from '@/config/api';
import store from "@/store";
import CryptoJS from 'crypto-js';
import { getServices } from '@/service/service';

export default {
  name: 'DoctorView',
  components: {
    Header, Footer
  },
  data() {
    return {
      user: null, //utente loggato
      doctor: null,
      services: null,
      month: 0,
      year: 0,
      selectedDate: null,
      selectedTime: null,
      availableTimes: [],
      comments: [],
      average: 0,
      isModalVisible: false,
      isSSModalVisible: false,
      modelStars: 5,
      modelComment: '',
      selectedDay: null,
      ss: []
    }
  },
  watch: {
    '$route'(to, from) {
      this.id = to.params.id;
      this.getDoctor();
    }
  },
  async created() {
    //Cancello tutte le date di tutti i dottori precendenti ad oggi
    this.agendaMassiveDelete();
    // Inizializzazione iniziale
    this.id = this.$route.params.id;
    await this.getDoctor();
    await this.loadServices();
    await this.getComments();
    await this.getAverageDoctor();
    await this.loadSecondaryStudios();
    console.log(this.ss)
  },
  async mounted() {
    await this.getDoctor();
    this.initDatePicker();
    if (store.hasToken) {
      this.me();
    }
  },

  methods: {
    modalToggle() {
      this.isModalVisible = !this.isModalVisible;
    },
    ssModalToggle() {
      this.isSSModalVisible = !this.isSSModalVisible;
    },
    formatDateFromString(dateString) {
      // Parse the input string into a Date object
      const date = new Date(dateString);

      // Get the day, month, year, hour, and minute from the Date object
      const day = date.getDate();
      const month = date.getMonth() + 1; // getMonth() returns a 0-based value, so we add 1
      const year = date.getFullYear();
      const hour = date.getHours();
      const minute = date.getMinutes();

      // Format the month as a string with the Italian name
      const monthNames = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"];
      const monthName = monthNames[month - 1];

      // Format the minute with two digits
      const minuteString = minute.toString().padStart(2, "0");

      // Return the formatted string
      return `${day} ${monthName} ${year} (${hour}:${minuteString})`;
    },
    async me() {
      const response = await request(
        apiConfig.noAuthEndpoints.me,
        apiConfig.methods.post,
        null,
        store.getToken()
      );
      if (response.ok) {
        const data = await response.json();
        this.user = data;
        if (this.user.role !== undefined) {
          this.isLogged = true
          this.isAdmin = this.user.role == "admin" || this.user.role == "editor";
        } else {
          this.isLogged = false
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
        responseKO();
      }
    },
    async loadSecondaryStudios() {
      const response = await request(
        apiConfig.endpoints.secondaryStudioList+"/"+this.doctor.id,
        apiConfig.methods.get,
        null,
        null,
      );
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.ss = data.result.studios;
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
        responseKO();
      }
    },
    initDatePicker() {
      const self = this;
      $('#calendar').datepicker({
        todayHighlight: true,
        weekStart: 1,
        format: "dd-mm-yyyy",
        language: 'it',
      }).on('changeMonth', function (e) {
        e.preventDefault();
        self.handleCalendarChange();
        // Click sul primo giorno del mese solo se non è stata selezionata una data
        self.selectedDay = null;
        self.clickFirstDayOfMonth();
      }).on('changeDate', function (e) {
        e.preventDefault();
        self.handleDateChange();
        // Aggiorna il giorno selezionato ogni volta che cambia la data
        self.selectedDay = $('#calendar').datepicker('getDate');
        //self.selectedDay = null;
      });

      $('#calendar').datepicker('setDate', new Date());
      this.handleCalendarChange();
    },

    clickFirstDayOfMonth() {
      this.$nextTick(() => {
        if (!this.selectedDay) {
          const firstDayElement = document.querySelector('.datepicker-days .day:not(.old):not(.new)');
          if (firstDayElement) {
            firstDayElement.click();
            this.selectedDay = null;
          }
        }
      });
    },

    handleCalendarChange() {
      // Riduci la visibilità del calendario qui se necessario
      this.getDateFromDatePickerSwitch();
      this.readMonth();
    },
    formatDate(dateString) {
      const [anno, mese, giorno] = dateString.split('-');
      const dataFormattata = `${giorno}/${mese.padStart(2, '0')}/${anno}`;
      return dataFormattata;
    },
    async handleDateChange() {
      const selectedDate = $('#calendar').datepicker('getDate');
      var data = new Date(selectedDate);
      var dataFormattata = [
        data.getFullYear(),
        this.padTo2Digits(data.getMonth() + 1),
        this.padTo2Digits(data.getDate())
      ].join('-');
      this.selectedDate = dataFormattata;
      await this.readDay();
      this.handleCalendarChange();
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, '0');
    },
    async agendaMassiveDelete() {
      const endpoint = apiConfig.noAuthEndpoints.agendaMassiveDelete;
      const response = await request(endpoint, apiConfig.methods.delete, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          //non faccio niente
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }

    },
    async getDoctor() {
      const endpoint = apiConfig.noAuthEndpoints.doctor + "/" + this.id;
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.doctor = data.result.doctor;
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }

    },
    async loadServices() {
      this.services = await getServices(this.doctor.id);
    },

    async readMonth() {
      const dayElements = document.querySelectorAll('.datepicker-days table td.day');

      // Disabilita tutti i giorni per poi riabilitarli in base alla risposta del server
      dayElements.forEach((el) => {
        el.classList.add('disabled', 'disabled-date', 'day');
      });

      // Prepara il body della richiesta
      const body = {
        user_id: this.doctor.user_id,
        month: this.month,
        year: this.year
      };

      // Effettua la richiesta al server
      const endpoint = apiConfig.noAuthEndpoints.agendaReadMonth;
      const response = await request(endpoint, apiConfig.methods.post, body, null);

      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          const days = data.result.days;

          //sfoglio i giorni del calendario per prepararli per il confronto con il set di giorni ricavato dal db
          dayElements.forEach((el) => {

            const elDay = parseInt(el.textContent, 10); // Estrai il giorno dal testo dell'elemento
            var elDate = new Date(this.year, this.month - 1, elDay);
            if (el.classList.contains("old")) {
              elDate.setMonth(elDate.getMonth() - 1);
            }
            if (el.classList.contains("new")) {
              elDate.setMonth(elDate.getMonth() + 1);
            }
            //sfoglio i giorni ricavati dal db e li confronto con quelli del calendario
            days.forEach((dayObj) => {
              const dayDate = new Date(dayObj.date);
              if (elDate.getFullYear() === dayDate.getFullYear() &&
                elDate.getMonth() === dayDate.getMonth() &&
                elDate.getDate() === dayDate.getDate()) {
                el.classList.remove('disabled', 'disabled-date');
              }
            });
          });
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000);
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
      }
    },

    async createAppointment() {
      const selectedServiceIds = this.services.filter(s => s.selected).map(s => s.id);
      if (selectedServiceIds.length == 0) {
        showNotification("Attenzione. Devi scegliere almeno un servizio.", "warning", 4000);
        return;
      }
      const body = {
        user_id: this.user.id,
        doctor_id: this.doctor.id,
        date: this.selectedDate + " " + this.selectedTime + ":00",
        serviceRequested: selectedServiceIds.join(',')
      }
      const endpoint = apiConfig.endpoints.appointmentCreate;
      const response = await request(endpoint, apiConfig.methods.post, body, store.getToken());
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          const appointment = data.result;
          //rimuovo la disponibilità del dottore
          const body2 = {
            user_id: this.doctor.user_id,
            date: this.selectedDate,
            times: [this.selectedTime]
          };
          const endpoint2 = apiConfig.endpoints.agendaDelete;
          const response2 = await request(endpoint2, apiConfig.methods.delete, body2, store.getToken());
          if (response2.ok) {
            const data2 = await response2.json();
            if (data2.message === "OK") {
              //non faccio niente
            } else {
              showNotification(data2.result.error, "error", 3000);
            }
          } else {
            showNotification(apiConfig.errors.generic, "error", 3000)
          }
          //Appuntamento creato con successo
          this.gotoToAppointmentCreated();
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000);
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    async readDay() {
      const body = {
        user_id: this.doctor.user_id,
        date: this.selectedDate
      };
      const endpoint = apiConfig.noAuthEndpoints.agendaReadDay
      const response = await request(endpoint, apiConfig.methods.post, body, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.availableTimes = data.result.times.map(timeObj => timeObj.time);
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },
    getDateFromDatePickerSwitch() {
      const datePickerSwitchElement = document.querySelector('.datepicker-switch');
      if (datePickerSwitchElement) {
        const datePickerSwitchText = datePickerSwitchElement.textContent;

        const monthMap = {
          "Gennaio": 1, "Febbraio": 2, "Marzo": 3, "Aprile": 4, "Maggio": 5, "Giugno": 6,
          "Luglio": 7, "Agosto": 8, "Settembre": 9, "Ottobre": 10, "Novembre": 11, "Dicembre": 12
        };

        const [monthText, year] = datePickerSwitchText.split(' ');

        const month = monthMap[monthText];

        // Aggiornare i dati del componente Vue
        this.month = month;
        this.year = parseInt(year); // Assicurati che l'anno sia un numero
      } else {
        //console.error('Elemento .datepicker-switch non trovato.');
      }
    },
    handleCalendar() {
      this.getDateFromDatePickerSwitch();
      this.readMonth();
    },
    gotoToAppointmentCreated() {
      const dataDaPassare = 'Gentile ' + this.user.name + ",<br>l'appuntamento con il dottor " + this.doctor.user.name + " per la data " + this.formatDate(this.selectedDate) + " " + this.selectedTime + " è stato creato.<br>La preghiamo di attendere una email di conferma, da parte del dottore, per la sua prenotazione.<br>Può controllare lo stato dei suoi appuntamenti nella sezione <a href='/profile'>profilo</a>.<br>La ringraziamo per aver scelto INFOESTETICA.";
      const encryptedData = CryptoJS.AES.encrypt(dataDaPassare, 'chiaveSegreta').toString();
      this.$router.push({ name: 'AppointmentCreated', query: { data: encryptedData } });
    },
    async prenota() {
      if (store.hasToken()) {
        //VERIFICO CHE IL DOTTORE NON SI PRENOTI PER SE STESSO
        if (this.doctor.user_id === this.user.id) {
          showNotification("Attenzione. Non puoi prenotare un servizio al dottore che coincide con l'utente loggato.", "warning", 4000);
          return;
        }
        if (this.user.role === 'admin') {
          showNotification("Attenzione. Un amministratore non può prendere appuntamenti.", "warning", 4000);
          return;
        }
        //PROCEDO CON L'APPUNTAMENTO
        if (!this.selectedDate || !this.selectedTime) {
          showNotification("Errore. Devi selezionare sia una data che un orario.", "error", 3000);
        } else {
          //faccio la prenotazione dell'appuntamento
          await this.createAppointment();
        }

      } else {
        showNotification("Errore. Per prenotare una visita occorre registrarsi al portale.", "error", 6000);
      }

    },

    async getComments() {
      const endpoint = apiConfig.endpoints.commentsListForDoctor + "/" + this.doctor.user.id
      const response = await request(endpoint, apiConfig.methods.get, null, null);
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.comments = data.result.comments;
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },

    async getAverageDoctor() {
      const endpoint = apiConfig.endpoints.commentsAverage + "/" + this.doctor.user.id
      const response = await request(endpoint, apiConfig.methods.get, null, store.getToken());
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          this.average = data.result.average_stars;
        } else {
          showNotification(apiConfig.errors.generic, "error", 3000)
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000)
      }
    },

    review() {
      if (this.user && this.user.role == 'user') {
        // -----
      } else {
        showNotification("Attenzione. Solo gli utenti registrati possono inserire una recensione.", "warning", 5000);
      }
    },

    async submitComment() {
      const body = {
        "author_id": this.user.id,
        "recipient_id": this.doctor.user_id,
        "stars": this.modelStars,
        "comment": this.modelComment
      }
      const endpoint = apiConfig.endpoints.commentsCreate;
      const response = await request(endpoint, apiConfig.methods.post, body, store.getToken());
      if (response.ok) {
        const data = await response.json();
        if (data.message === "OK") {
          await this.getComments();
          this.modelStars = 5;
          this.modelComment = '';
          this.modalToggle();
          document.location.reload();
        } else {
          showNotification(data.message, "error", 3000);
        }
      } else {
        showNotification(apiConfig.errors.generic, "error", 3000);
      }
    },

    async deleteComment(commentId) {
      // Chiamata API per cancellare il commento
      const endpoint = apiConfig.endpoints.commentsDelete + "/" + commentId;
      const response = await request(endpoint, apiConfig.methods.delete, null, store.getToken());
      if (response.ok) {
        document.location.reload();
      } else {
        showNotification("Errore nella cancellazione del commento", "error", 3000);
      }
    },


  },

}
</script>

<style scoped>
.statistic li {
  margin-right: 5px;
}

/* stile per il modal */
.modal {
  display: block;
  position: fixed;
  z-index: 100;
  padding-top: 15%;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 40%;
  top: -100px;
  max-height: 80%;
  overflow-y: auto;
}

.close {
  cursor: pointer;
  display: block;
  z-index: 200px;
  color: #515151;
  text-align: right;
  font-size: 28px;
  font-weight: bold;
  margin-right: 10px;
}

.close:hover,
.close:focus {
  color: #aaaaaa;
  text-decoration: none;
  cursor: pointer;
}
</style>