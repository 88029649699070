<template>

    <div>
        <AdminLTEMenu />

        <div class="content-wrapper">

            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Dashboard</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/admin">Home</a></li>
                                <li class="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="row">

                        <div class="col-lg-3 col-6">
                            <div class="small-box bg-warning">
                                <div class="inner">
                                    <h3>{{ userCount }}</h3>
                                    <p>Solo utenti e dottori</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-person-add"></i>
                                </div>
                                <a v-if="user && user.role === 'admin'" href="/admin/users" class="small-box-footer">Vai alla sezione... <i
                                    class="fas fa-arrow-circle-right"></i></a>
                                <a v-else href="#" class="small-box-footer" data-toggle="modal" data-target="#modal-lg"><i
                                    class="fa-regular fa-circle-question"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <div class="small-box bg-info">
                                <div class="inner">
                                    <h3>{{ commentCount }}</h3>
                                    <p>Commenti</p>
                                </div>
                                <div class="icon">
                                    <i class="fas fa-comment"></i>
                                </div>
                                <a v-if="user && user.role === 'admin'" href="/admin/comments" class="small-box-footer">Vai alla sezione... <i
                                    class="fas fa-arrow-circle-right"></i></a>
                                <a v-else href="#" class="small-box-footer" data-toggle="modal" data-target="#modal-lg"><i
                                    class="fa-regular fa-circle-question"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">

                            <div class="small-box bg-success">
                                <div class="inner">
                                    <h3>{{ confirmAppCount }}</h3>
                                    <p>Appuntamenti confermati</p>
                                </div>
                                <div class="icon">
                                    <i class="fa-solid fa-calendar-days"></i>
                                </div>
                                <a href="#" class="small-box-footer" data-toggle="modal" data-target="#modal-lg"><i
                                    class="fa-regular fa-circle-question"></i></a>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">

                            <div class="small-box bg-danger">
                                <div class="inner">
                                    <h3>{{ notConfirmAppCount }}</h3>
                                    <p>Appuntamenti non confermati</p>
                                </div>
                                <div class="icon">
                                    <i class="fa-solid fa-calendar-days"></i>
                                </div>

                                <a href="#" class="small-box-footer" data-toggle="modal" data-target="#modal-lg"><i
                                        class="fa-regular fa-circle-question"></i></a>

                            </div>
                        </div>

                    </div>
                </div>
            </section>


        </div>

    </div>

    <div class="modal fade" id="modal-lg" aria-hidden="true" style="display: none;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Appuntamenti</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>Le statistiche visualizzate includono gli utenti registrati solamente di ruolo utente o dottore e gli appuntamenti confermati e non confermati a partire dalla data odierna. Tutti gli appuntamenti precedenti vengono rimossi.</p>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Chiudi</button>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import AdminLTEMenu from '../../components/AdminComponents/AdminLTEMenu.vue'
import apiConfig from '@/config/api';
import store from "@/store";
import { request, responseKO } from '@/config/api'

export default {
    components: {
        AdminLTEMenu
    },
    data() {
        return {
            user: null,
            userCount: 0,
            commentCount: 0,
            confirmAppCount: 0,
            notConfirmAppCount: 0
        }
    },
    methods: {
        isObjectEmpty(obj) {
            return Object.keys(obj).length === 0 && obj.constructor === Object;
        },
        async me() {
            const response = await request(
                apiConfig.noAuthEndpoints.me,
                apiConfig.methods.post,
                null,
                store.getToken()
            );
            if (response.ok) {
                const data = await response.json();
                this.user = data;
                if (!this.isObjectEmpty(this.user)) {
                    if (this.user.role != 'admin' && this.user.role != 'editor') {
                        responseKO();
                    }
                } else {
                    responseKO();
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async getCommentsCount() {
            const response = await request(apiConfig.endpoints.dashboardComment, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                this.commentCount = data.commentCount;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async getUsersCount() {
            const response = await request(apiConfig.endpoints.dashboardUser, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                this.userCount = data.userCount;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async getConfirmedAppCount() {
            const response = await request(apiConfig.endpoints.dashboardConfirmedApp, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                this.confirmAppCount = data.count;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async getNotConfirmedAppCount() {
            const response = await request(apiConfig.endpoints.dashboardNotConfirmedApp, apiConfig.methods.get, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                this.notConfirmAppCount = data.count;
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
        async deletePreviousAppointments() {
            const endpoint = apiConfig.endpoints.appointmentDeleteAll;
            const response = await request(endpoint, apiConfig.methods.delete, null, store.getToken());
            if (response.ok) {
                const data = await response.json();
                if (data.message === "OK") {
                    //non faccio niente
                } else {
                    showNotification(data.result.error, "error", 3000);
                }
            } else {
                showNotification(apiConfig.errors.generic, "error", 3000);
            }
        },
    },
    created() {
        this.me();
    },
    mounted() {
        //prima di tutti cancello gli appuntamenti vecchi
        this.deletePreviousAppointments();
        //continuo con la raccolta delle statistiche
        this.getCommentsCount();
        this.getUsersCount();
        this.getConfirmedAppCount();
        this.getNotConfirmedAppCount();
    }
}
</script>